import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  EmailAddress: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JWT: { input: any; output: any; }
  Latitude: { input: any; output: any; }
  Longitude: { input: any; output: any; }
  PositiveInt: { input: any; output: any; }
  PostalCode: { input: any; output: any; }
  Void: { input: any; output: any; }
};

export type AccessTokens = {
  __typename?: 'AccessTokens';
  accessToken?: Maybe<Scalars['JWT']['output']>;
  refreshToken?: Maybe<Scalars['JWT']['output']>;
};

export type Account = {
  __typename?: 'Account';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['EmailAddress']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastActive?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};

export type AddCheckoutDiscountInput = {
  discountName: Scalars['String']['input'];
  producerId: Scalars['ID']['input'];
};

export type AddToCartInput = {
  cartId: Scalars['ID']['input'];
  category: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  storeId: Scalars['ID']['input'];
  subCategory: Scalars['String']['input'];
  type: ProductVariantType;
  unit: Scalars['String']['input'];
  unitQuantity?: InputMaybe<Scalars['Int']['input']>;
  variantName?: InputMaybe<Scalars['String']['input']>;
};

export enum AddressType {
  Billing = 'BILLING',
  Shipping = 'SHIPPING'
}

export type AdminView = {
  __typename?: 'AdminView';
  columns: Scalars['JSON']['output'];
  filters: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
  type: AdminViewType;
};

export type AdminViewCreateInput = {
  columns: Scalars['JSON']['input'];
  filters: Scalars['JSON']['input'];
  name: Scalars['String']['input'];
  type: AdminViewType;
};

export type AdminViewDeleteInput = {
  id: Scalars['ID']['input'];
};

export type AdminViewDeleted = {
  __typename?: 'AdminViewDeleted';
  id: Scalars['ID']['output'];
};

export type AdminViewSetSelectedInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  type: AdminViewType;
};

export enum AdminViewType {
  HarvestingAggregatedForecast = 'HARVESTING_AGGREGATED_FORECAST',
  HarvestingForecast = 'HARVESTING_FORECAST',
  Orders = 'ORDERS',
  Plantings = 'PLANTINGS',
  TasksHarvesting = 'TASKS_HARVESTING',
  TasksOverview = 'TASKS_OVERVIEW',
  TasksSeedingInField = 'TASKS_SEEDING_IN_FIELD',
  TasksSeedingInTray_1 = 'TASKS_SEEDING_IN_TRAY_1',
  TasksTransplantToField = 'TASKS_TRANSPLANT_TO_FIELD',
  TasksTransplantToTray_2 = 'TASKS_TRANSPLANT_TO_TRAY_2'
}

export type AggregatedOrderLineItem = Node & {
  __typename?: 'AggregatedOrderLineItem';
  category: Scalars['String']['output'];
  crops?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  name: Scalars['String']['output'];
  orderedQuantity?: Maybe<Scalars['Float']['output']>;
  orders?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  productId?: Maybe<Scalars['ID']['output']>;
  unit: Scalars['String']['output'];
  variantId?: Maybe<Scalars['ID']['output']>;
};

export type AggregatedOrderLineItemConnection = {
  __typename?: 'AggregatedOrderLineItemConnection';
  edges: Array<Maybe<AggregatedOrderLineItemEdge>>;
  pageInfo: PageInfo;
};

export type AggregatedOrderLineItemEdge = {
  __typename?: 'AggregatedOrderLineItemEdge';
  node?: Maybe<AggregatedOrderLineItem>;
};

export type AggregatedPlanting = Node & {
  __typename?: 'AggregatedPlanting';
  count?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crop?: Maybe<Crop>;
  endHarvestDate?: Maybe<Scalars['DateTime']['output']>;
  harvestDate?: Maybe<Scalars['DateTime']['output']>;
  harvestWeeks?: Maybe<Scalars['Float']['output']>;
  harvestingUnit?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locationIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  locations?: Maybe<Array<Maybe<Location>>>;
  quantityPerWeek?: Maybe<Scalars['Float']['output']>;
  sites?: Maybe<Array<Maybe<Site>>>;
  subCategory?: Maybe<SubCategory>;
  variant?: Maybe<CropVariant>;
  variantId?: Maybe<Scalars['ID']['output']>;
  variantIdOverride?: Maybe<Scalars['ID']['output']>;
  weeklyHarvest?: Maybe<Array<Maybe<WeeklyHarvest>>>;
  worklogs?: Maybe<Array<Maybe<Worklog>>>;
};

export type AggregatedPlantingConnection = {
  __typename?: 'AggregatedPlantingConnection';
  edges: Array<Maybe<AggregatedPlantingEdge>>;
  pageInfo: PageInfo;
};

export type AggregatedPlantingEdge = {
  __typename?: 'AggregatedPlantingEdge';
  node?: Maybe<AggregatedPlanting>;
};

export type BatchCreateHarvestingTasksFromVariantsInput = {
  harvestDate?: InputMaybe<Scalars['DateTime']['input']>;
  variants: Array<InputMaybe<BatchCreateHarvestingTasksVariantInput>>;
};

export type BatchCreateHarvestingTasksInput = {
  tasks: Array<InputMaybe<CreateTaskInput>>;
};

export type BatchCreateHarvestingTasksVariantInput = {
  id: Scalars['ID']['input'];
  quantity: Scalars['Float']['input'];
};

export type BatchDeleteInput = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type BatchDraft = {
  __typename?: 'BatchDraft';
  id: Scalars['ID']['output'];
};

export type BatchLocation = {
  __typename?: 'BatchLocation';
  id: Scalars['ID']['output'];
};

export type Bounds = {
  __typename?: 'Bounds';
  ne?: Maybe<Coordinate>;
  sw?: Maybe<Coordinate>;
};

export type BoundsInput = {
  ne?: InputMaybe<CoordinateInput>;
  sw?: InputMaybe<CoordinateInput>;
};

export type Cart = {
  __typename?: 'Cart';
  createdAt: Scalars['DateTime']['output'];
  grandTotal: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<CartItem>>;
  minimumOrderAmount: Scalars['Int']['output'];
  minimumOrderQuantity: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  store: Store;
  subTotal: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalUniqueItems: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CartEdge = {
  __typename?: 'CartEdge';
  node?: Maybe<Cart>;
};

export type CartItem = {
  __typename?: 'CartItem';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  lineTotal: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  unit?: Maybe<Scalars['String']['output']>;
  unitQuantity?: Maybe<Scalars['Int']['output']>;
  unitTotal: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variantName?: Maybe<Scalars['String']['output']>;
};

export type CartsConnection = {
  __typename?: 'CartsConnection';
  edges: Array<Maybe<CartEdge>>;
  id: Scalars['ID']['output'];
};

export type Category = {
  __typename?: 'Category';
  activeCount: Scalars['Int']['output'];
  children?: Maybe<Array<Category>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  producerId: Scalars['ID']['output'];
  totalCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  edges?: Maybe<Array<CategoryEdge>>;
  pageInfo: PageInfo;
};

export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  node: Category;
};

export enum CertificationStatus {
  Organic = 'organic',
  Transitional = 'transitional'
}

export type ChangePasswordInput = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type CheckoutAddress = {
  __typename?: 'CheckoutAddress';
  address1: Scalars['String']['output'];
  city: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  province?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export type CheckoutOrder = Node & {
  __typename?: 'CheckoutOrder';
  billingAddress?: Maybe<CheckoutAddress>;
  createdAt: Scalars['DateTime']['output'];
  discountTotal: Scalars['Int']['output'];
  financialStatus: FinancialStatus;
  grandTotal: Scalars['Int']['output'];
  hash: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  lineItems: Array<Maybe<OrderLineItem>>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<Payment>;
  producer: Producer;
  refundedTotal: Scalars['Int']['output'];
  serviceFee: Scalars['Int']['output'];
  shipping?: Maybe<CheckoutOrderShipping>;
  shippingAddress?: Maybe<CheckoutAddress>;
  shippingTotal: Scalars['Int']['output'];
  status: OrderStatus;
  subTotal: Scalars['Int']['output'];
  taxTotal: Scalars['Int']['output'];
};

export type CheckoutOrderShipping = {
  __typename?: 'CheckoutOrderShipping';
  date?: Maybe<Scalars['DateTime']['output']>;
  location?: Maybe<ProducerLocation>;
  type?: Maybe<OrderShippingType>;
};

export type CheckoutPaymentMethod = {
  __typename?: 'CheckoutPaymentMethod';
  brand?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  type?: Maybe<PaymentMethodType>;
};

export enum CoCropsType {
  Child = 'child',
  Parent = 'parent'
}

export type CompleteCheckoutInput = {
  cartId: Scalars['ID']['input'];
  checkoutId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export type CompletedBy = {
  __typename?: 'CompletedBy';
  id: Scalars['ID']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type Coordinate = {
  __typename?: 'Coordinate';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type CoordinateInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  bounds?: Maybe<Bounds>;
  center?: Maybe<Coordinate>;
  polygon?: Maybe<Array<Maybe<Coordinate>>>;
};

export type CoordinatesInput = {
  bounds?: InputMaybe<BoundsInput>;
  center?: InputMaybe<CoordinateInput>;
  polygon?: InputMaybe<Array<InputMaybe<CoordinateInput>>>;
};

export type CreateCardPaymentMethodInput = {
  id: Scalars['ID']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['String']['input'];
};

export type CreateCategoryInput = {
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateCheckoutInput = {
  checkoutId: Scalars['ID']['input'];
  storeId: Scalars['ID']['input'];
};

export type CreateCheckoutPaymentMethodInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  checkoutId: Scalars['ID']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodType: PaymentMethodType;
  publicToken?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFertilizerInput = {
  name: Scalars['String']['input'];
};

export type CreateInventoryLocationInput = {
  coordinates?: InputMaybe<LocationCoordinatesInput>;
  name: Scalars['String']['input'];
  type: InventoryLocationType;
};

export type CreatePesticideInput = {
  name: Scalars['String']['input'];
};

export type CreatePesticideInventoryEntryInput = {
  container?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  pesticideId?: InputMaybe<Scalars['ID']['input']>;
  purchaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  purchaseLotNumber?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Float']['input'];
  supplier?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unit: Scalars['String']['input'];
  user?: InputMaybe<InventoryEntryUserInput>;
};

export type CreatePlaidPaymentMethodInput = {
  accountId: Scalars['String']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  publicToken: Scalars['String']['input'];
};

export type CreateSeedInput = {
  name: Scalars['String']['input'];
};

export type CreateTagInput = {
  color: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: TagType;
};

export type CreateTaskInput = {
  cropId: Scalars['ID']['input'];
  harvestDate: Scalars['DateTime']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
  plantingId?: InputMaybe<Scalars['ID']['input']>;
  quantity: Scalars['Float']['input'];
  seedInventoryEntryId?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<TaskStatus>;
  type: TaskType;
  unit: Scalars['String']['input'];
  variantId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateTasksInput = {
  plantingIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type CreateTeamMemberInput = {
  email: Scalars['EmailAddress']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type CreateUserAddressInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  countryCode?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  types: Array<AddressType>;
  unit?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type CreateUserInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailAddress']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  type: CustomerType;
  verifyUrl: Scalars['String']['input'];
};

export type Crop = Node & {
  __typename?: 'Crop';
  category?: Maybe<Category>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  germinationRate?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  name: Scalars['String']['output'];
  subCategory?: Maybe<SubCategory>;
  templates?: Maybe<Array<Maybe<Template>>>;
  totalVariants: Scalars['Int']['output'];
  unit?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  variants?: Maybe<Array<Maybe<CropVariant>>>;
};

export type CropConnection = {
  __typename?: 'CropConnection';
  edges: Array<Maybe<CropEdge>>;
  pageInfo: PageInfo;
};

export type CropCreateInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  germinationRate?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<CropImageInput>;
  name: Scalars['String']['input'];
  subCategoryId?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  variants?: InputMaybe<Array<InputMaybe<CropVariantInput>>>;
};

export type CropDelete = {
  __typename?: 'CropDelete';
  id: Scalars['ID']['output'];
};

export type CropDeleteBatchInput = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type CropEdge = {
  __typename?: 'CropEdge';
  node?: Maybe<Crop>;
};

export type CropImageInput = {
  base64: Scalars['String']['input'];
  blurhash: Scalars['String']['input'];
  src: Scalars['String']['input'];
};

export type CropInventoryCombinedFilter = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  cropId?: InputMaybe<Scalars['ID']['input']>;
  cropName?: InputMaybe<Scalars['String']['input']>;
  dateRange?: InputMaybe<SearchDateRange>;
  difference?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  needsHarvest?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CropInventoryEntry = {
  __typename?: 'CropInventoryEntry';
  container?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  crop?: Maybe<Crop>;
  cropId?: Maybe<Scalars['ID']['output']>;
  cropVariant?: Maybe<CropVariant>;
  id: Scalars['ID']['output'];
  location?: Maybe<InventoryLocation>;
  locationId?: Maybe<Scalars['ID']['output']>;
  lotNumber?: Maybe<Scalars['String']['output']>;
  purchaseDate?: Maybe<Scalars['DateTime']['output']>;
  quantity: Scalars['Float']['output'];
  supplier?: Maybe<Scalars['String']['output']>;
  unit: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<InventoryEntryUser>;
  variantId?: Maybe<Scalars['String']['output']>;
};

export type CropInventoryEntryConnection = {
  __typename?: 'CropInventoryEntryConnection';
  edges: Array<Maybe<CropInventoryEntryEdge>>;
  pageInfo: PageInfo;
};

export type CropInventoryEntryEdge = {
  __typename?: 'CropInventoryEntryEdge';
  node?: Maybe<CropInventoryEntry>;
};

export type CropInventoryEntryFilter = {
  createdAt?: InputMaybe<SearchDateRange>;
  cropId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
};

export type CropInventoryEntryInput = {
  container?: InputMaybe<Scalars['String']['input']>;
  cropId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  lotNumber?: InputMaybe<Scalars['String']['input']>;
  purchaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  quantity: Scalars['Float']['input'];
  supplier?: InputMaybe<Scalars['String']['input']>;
  unit: Scalars['String']['input'];
  user?: InputMaybe<InventoryEntryUserInput>;
  variantId?: InputMaybe<Scalars['String']['input']>;
};

export enum CropUnitTypes {
  Buckets = 'buckets',
  Bunches = 'bunches',
  Crates = 'crates',
  Each = 'each',
  HalfPints = 'half_pints',
  Heads = 'heads',
  Lb = 'lb',
  OneHundrethOfAcre = 'one_hundreth_of_acre',
  Pieces = 'pieces',
  Pints = 'pints',
  PlantingBeds = 'planting_beds',
  Pounds = 'pounds',
  Stalks = 'stalks'
}

export type CropUpdateInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  cropId: Scalars['ID']['input'];
  germinationRate?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<CropImageInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  subCategoryId?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  variants?: InputMaybe<Array<InputMaybe<CropVariantInput>>>;
};

export type CropVariant = {
  __typename?: 'CropVariant';
  baseTemperature?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  daysToMaturity?: Maybe<Scalars['Int']['output']>;
  growingDegreeDaysToMaturity?: Maybe<Scalars['Int']['output']>;
  harvestDuration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  name: Scalars['String']['output'];
  singleDayHarvest?: Maybe<Scalars['Boolean']['output']>;
  tray1Days?: Maybe<Scalars['Int']['output']>;
  tray1MaxDays?: Maybe<Scalars['Int']['output']>;
  tray2Days?: Maybe<Scalars['Int']['output']>;
  tray2MaxDays?: Maybe<Scalars['Int']['output']>;
};

export type CropVariantInput = {
  baseTemperature?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  daysToMaturity?: InputMaybe<Scalars['Int']['input']>;
  growingDegreeDaysToMaturity?: InputMaybe<Scalars['Int']['input']>;
  harvestDuration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<CropImageInput>;
  name: Scalars['String']['input'];
  singleDayHarvest?: InputMaybe<Scalars['Boolean']['input']>;
  tray1Days?: InputMaybe<Scalars['Int']['input']>;
  tray1MaxDays?: InputMaybe<Scalars['Int']['input']>;
  tray2Days?: InputMaybe<Scalars['Int']['input']>;
  tray2MaxDays?: InputMaybe<Scalars['Int']['input']>;
};

export type CropWizardInput = {
  baseTemperature?: InputMaybe<Scalars['Int']['input']>;
  daysToMaturity?: InputMaybe<Scalars['Int']['input']>;
  growingDegreeDaysToMaturity?: InputMaybe<Scalars['Int']['input']>;
  harvestDuration?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  tray1Days?: InputMaybe<Scalars['Int']['input']>;
  tray1MaxDays?: InputMaybe<Scalars['Int']['input']>;
  tray2Days?: InputMaybe<Scalars['Int']['input']>;
  tray2MaxDays?: InputMaybe<Scalars['Int']['input']>;
  variantName: Scalars['String']['input'];
};

export type CropWizardResponse = {
  __typename?: 'CropWizardResponse';
  baseTemperature?: Maybe<Scalars['Int']['output']>;
  daysToMaturity?: Maybe<Scalars['Int']['output']>;
  growingDegreeDaysToMaturity?: Maybe<Scalars['Int']['output']>;
  harvestDuration?: Maybe<Scalars['Int']['output']>;
  singleDayHarvest?: Maybe<Scalars['Boolean']['output']>;
  tray1Days?: Maybe<Scalars['Int']['output']>;
  tray1MaxDays?: Maybe<Scalars['Int']['output']>;
  tray2Days?: Maybe<Scalars['Int']['output']>;
  tray2MaxDays?: Maybe<Scalars['Int']['output']>;
};

export type Customer = Node & {
  __typename?: 'Customer';
  accounts: Array<Maybe<CustomerAccount>>;
  billingAddresses: Array<Maybe<CustomerAddress>>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['EmailAddress']['output'];
  emailSubscriptions: Array<Maybe<EmailSubscription>>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inviteCode?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastOrder?: Maybe<CustomerLastOrder>;
  numberOfOrders?: Maybe<Scalars['Int']['output']>;
  paymentMethods: Array<Maybe<PaymentMethod>>;
  phone?: Maybe<Scalars['String']['output']>;
  shippingAddresses: Array<Maybe<CustomerAddress>>;
  type: CustomerType;
  username?: Maybe<Scalars['String']['output']>;
};

export type CustomerAccount = {
  __typename?: 'CustomerAccount';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inviteCode?: Maybe<Scalars['String']['output']>;
  lastActive?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type CustomerAccountCreateInput = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  email: Scalars['EmailAddress']['input'];
  firstName: Scalars['String']['input'];
  inviteCode?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  sendInvite?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerAccountDeleteInput = {
  accountId: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
};

export type CustomerAccountUpdateInput = {
  accountId: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['Latitude']['output']>;
  longitude?: Maybe<Scalars['Longitude']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export type CustomerAddressCreateInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  countryCode?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  type: AddressType;
  unit?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type CustomerAddressDeleteInput = {
  addressId: Scalars['ID']['input'];
  customerId?: InputMaybe<Scalars['ID']['input']>;
};

export type CustomerAddressUpdateInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  addressId: Scalars['ID']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  edges: Array<Maybe<CustomerEdge>>;
  pageInfo: PageInfo;
};

export type CustomerCreateInput = {
  accounts?: InputMaybe<Array<InputMaybe<CustomerAccountCreateInput>>>;
  billingAddresses?: InputMaybe<Array<InputMaybe<CustomerAddressCreateInput>>>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailAddress']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  shippingAddresses?: InputMaybe<Array<InputMaybe<CustomerAddressCreateInput>>>;
  type: CustomerType;
};

export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  node: Customer;
};

export type CustomerLastOrder = {
  __typename?: 'CustomerLastOrder';
  amountTotal?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<Scalars['String']['output']>;
  shippingDate?: Maybe<Scalars['DateTime']['output']>;
  shippingType?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type CustomerResetInviteCodeInput = {
  customerId: Scalars['ID']['input'];
};

export enum CustomerType {
  Anonymous = 'ANONYMOUS',
  Retail = 'RETAIL',
  Wholesale = 'WHOLESALE'
}

export type CustomerUpdateAccountInput = {
  email: Scalars['EmailAddress']['input'];
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerUpdateAddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['ID']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerUpdateEmailSubscriptionInput = {
  confirmation?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['EmailAddress']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  invoice?: InputMaybe<Scalars['Boolean']['input']>;
  news?: InputMaybe<Scalars['Boolean']['input']>;
  orderFulfillment?: InputMaybe<Scalars['Boolean']['input']>;
  orderUpdated?: InputMaybe<Scalars['Boolean']['input']>;
  paymentConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerUpdateInput = {
  accounts?: InputMaybe<Array<InputMaybe<CustomerUpdateAccountInput>>>;
  billingAddresses?: InputMaybe<Array<InputMaybe<CustomerUpdateAddressInput>>>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  emailSubscriptions?: InputMaybe<Array<InputMaybe<CustomerUpdateEmailSubscriptionInput>>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  shippingAddresses?: InputMaybe<Array<InputMaybe<CustomerUpdateAddressInput>>>;
  type?: InputMaybe<CustomerType>;
};

export type DeleteTasksInput = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type DeliveryCompany = {
  __typename?: 'DeliveryCompany';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DistanceType = {
  __typename?: 'DistanceType';
  km?: Maybe<Scalars['String']['output']>;
  mi?: Maybe<Scalars['String']['output']>;
};

export type Draft = {
  __typename?: 'Draft';
  billingAddress?: Maybe<DraftAddress>;
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<DraftCustomer>;
  discountTotal: Scalars['Int']['output'];
  grandTotal: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lineItems?: Maybe<Array<Maybe<DraftLineItem>>>;
  name: Scalars['String']['output'];
  payment?: Maybe<Payment>;
  refundedTotal: Scalars['Int']['output'];
  serviceFee: Scalars['Int']['output'];
  shipping?: Maybe<DraftShipping>;
  shippingAddress?: Maybe<DraftAddress>;
  shippingTotal: Scalars['Int']['output'];
  subTotal: Scalars['Int']['output'];
  taxTotal: Scalars['Int']['output'];
  type: CustomerType;
};

export type DraftAddCustomerInput = {
  customerId: Scalars['ID']['input'];
  draftId: Scalars['ID']['input'];
};

export type DraftAddItemInput = {
  draftId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  variantId: Scalars['ID']['input'];
};

export type DraftAddress = {
  __typename?: 'DraftAddress';
  address1: Scalars['String']['output'];
  city: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  province?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export type DraftConnection = {
  __typename?: 'DraftConnection';
  edges: Array<Maybe<DraftEdge>>;
  pageInfo: PageInfo;
};

export type DraftCustomer = {
  __typename?: 'DraftCustomer';
  companyName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  type: CustomerType;
};

export type DraftDeleteManyInput = {
  draftIds: Array<Scalars['ID']['input']>;
};

export type DraftEdge = {
  __typename?: 'DraftEdge';
  node: Draft;
};

export type DraftLineItem = {
  __typename?: 'DraftLineItem';
  discountTotal?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  image: Image;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  unit: Scalars['String']['output'];
  unitPrice: Scalars['Float']['output'];
  unitQuantity: Scalars['PositiveInt']['output'];
};

export type DraftRemoveItemInput = {
  draftId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
};

export type DraftShipping = {
  __typename?: 'DraftShipping';
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryCompany?: Maybe<DeliveryCompany>;
  fee?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<ProducerLocation>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum DraftShippingType {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP'
}

export type DraftUpdateBillingAddressInput = {
  billingAddressId: Scalars['String']['input'];
  customerId: Scalars['ID']['input'];
  draftId: Scalars['ID']['input'];
};

export type DraftUpdateDeliveryFeeInput = {
  draftId: Scalars['ID']['input'];
  fee: Scalars['Int']['input'];
};

export type DraftUpdateItemInput = {
  draftId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
};

export type DraftUpdatePaymentMethodInput = {
  cardId?: InputMaybe<Scalars['ID']['input']>;
  customerId: Scalars['ID']['input'];
  draftId: Scalars['ID']['input'];
  type: PaymentMethodType;
};

export type DraftUpdateShippingAddressInput = {
  customerId: Scalars['ID']['input'];
  draftId: Scalars['ID']['input'];
  shippingAddressId: Scalars['String']['input'];
};

export type DraftUpdateShippingInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryCompanyId?: InputMaybe<Scalars['ID']['input']>;
  draftId: Scalars['ID']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
  type: DraftShippingType;
};

export type EmailSubscription = {
  __typename?: 'EmailSubscription';
  confirmation?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice?: Maybe<Scalars['Boolean']['output']>;
  news?: Maybe<Scalars['Boolean']['output']>;
  orderFulfillment?: Maybe<Scalars['Boolean']['output']>;
  orderUpdated?: Maybe<Scalars['Boolean']['output']>;
  paymentConfirmation?: Maybe<Scalars['Boolean']['output']>;
};

export type Fertilizer = {
  __typename?: 'Fertilizer';
  applicationRate?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inventoryEntries: Array<FertilizerInventoryEntry>;
  name: Scalars['String']['output'];
  nutrientContent?: Maybe<Scalars['String']['output']>;
  producer: Producer;
  updatedAt: Scalars['DateTime']['output'];
};

export type FertilizerConnection = {
  __typename?: 'FertilizerConnection';
  edges: Array<Maybe<FertilizerEdge>>;
  pageInfo: PageInfo;
};

export type FertilizerEdge = {
  __typename?: 'FertilizerEdge';
  node?: Maybe<Fertilizer>;
};

export type FertilizerFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FertilizerInventoryEntry = {
  __typename?: 'FertilizerInventoryEntry';
  container?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fertilizer?: Maybe<Fertilizer>;
  fertilizerId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<InventoryLocation>;
  locationId?: Maybe<Scalars['ID']['output']>;
  lotNumber?: Maybe<Scalars['String']['output']>;
  purchaseDate?: Maybe<Scalars['DateTime']['output']>;
  purchaseLotNumber?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  supplier?: Maybe<Scalars['String']['output']>;
  unit: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<InventoryEntryUser>;
};

export type FertilizerInventoryEntryConnection = {
  __typename?: 'FertilizerInventoryEntryConnection';
  edges: Array<Maybe<FertilizerInventoryEntryEdge>>;
  pageInfo: PageInfo;
};

export type FertilizerInventoryEntryEdge = {
  __typename?: 'FertilizerInventoryEntryEdge';
  node?: Maybe<FertilizerInventoryEntry>;
};

export type FertilizerInventoryEntryFilter = {
  createdAt?: InputMaybe<SearchDateRange>;
  fertilizerId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
};

export type FertilizerInventoryEntryInput = {
  container?: InputMaybe<Scalars['String']['input']>;
  fertilizerId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  purchaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  purchaseLotNumber?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Float']['input'];
  supplier?: InputMaybe<Scalars['String']['input']>;
  unit: Scalars['String']['input'];
  user?: InputMaybe<InventoryEntryUserInput>;
};

export enum FertilizerUnitTypes {
  Lb = 'lb'
}

export type FilterInput = {
  completedFrom?: InputMaybe<Scalars['DateTime']['input']>;
  completedTo?: InputMaybe<Scalars['DateTime']['input']>;
  cropName?: InputMaybe<Scalars['String']['input']>;
  exclude?: InputMaybe<TaskType>;
  excludeCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  lotNumber?: InputMaybe<Scalars['String']['input']>;
  plantingId?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<TaskStatus>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<TaskType>;
};

export enum FinancialStatus {
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Unpaid = 'UNPAID'
}

export type ForgotPassword = {
  __typename?: 'ForgotPassword';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ForgotPasswordInput = {
  callbackUrl: Scalars['String']['input'];
  email: Scalars['EmailAddress']['input'];
};

export type Image = {
  __typename?: 'Image';
  alt?: Maybe<Scalars['String']['output']>;
  base64?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  src?: Maybe<Scalars['String']['output']>;
};

export enum InputType {
  Acres = 'ACRES',
  BedFeet = 'BED_FEET',
  HarvestQuantity = 'HARVEST_QUANTITY',
  Trays = 'TRAYS'
}

export type InventoryCombinedConnection = {
  __typename?: 'InventoryCombinedConnection';
  edges: Array<Maybe<InventoryCombinedEdge>>;
  pageInfo: PageInfo;
};

export type InventoryCombinedEdge = {
  __typename?: 'InventoryCombinedEdge';
  node?: Maybe<InventoryCombinedEntry>;
};

export type InventoryCombinedEntry = {
  __typename?: 'InventoryCombinedEntry';
  crop?: Maybe<Crop>;
  cropId?: Maybe<Scalars['ID']['output']>;
  harvestQuantity: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  inventoryQuantity: Scalars['Float']['output'];
  neededQuantity: Scalars['Float']['output'];
  orderedQuantity: Scalars['Float']['output'];
  quantityDifference: Scalars['Float']['output'];
  totalQuantity: Scalars['Float']['output'];
  unit: Scalars['String']['output'];
  variantId: Scalars['String']['output'];
};

export enum InventoryEntryType {
  Crop = 'Crop',
  Fertilizer = 'Fertilizer',
  Pesticide = 'Pesticide',
  Seed = 'Seed'
}

export type InventoryEntryUser = {
  __typename?: 'InventoryEntryUser';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type InventoryEntryUserInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type InventoryLocation = {
  __typename?: 'InventoryLocation';
  coordinates?: Maybe<LocationCoordinates>;
  createdAt: Scalars['DateTime']['output'];
  crops?: Maybe<Array<Maybe<CropInventoryEntry>>>;
  fertilizers?: Maybe<Array<Maybe<FertilizerInventoryEntry>>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pesticides?: Maybe<Array<Maybe<PesticideInventoryEntry>>>;
  seeds?: Maybe<Array<Maybe<SeedInventoryEntry>>>;
  type: InventoryLocationType;
  updatedAt: Scalars['DateTime']['output'];
};

export type InventoryLocationConnection = {
  __typename?: 'InventoryLocationConnection';
  edges: Array<Maybe<InventoryLocationEdge>>;
  pageInfo: PageInfo;
};

export type InventoryLocationEdge = {
  __typename?: 'InventoryLocationEdge';
  node?: Maybe<InventoryLocation>;
};

export type InventoryLocationFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export enum InventoryLocationType {
  Cooler = 'cooler',
  Storage = 'storage'
}

export type InventoryNotification = {
  __typename?: 'InventoryNotification';
  inventoryCondition: NotificationCondition;
  inventoryItem: Scalars['String']['output'];
  inventoryType: InventoryEntryType;
  inventoryValue: Scalars['Float']['output'];
};

export type InventoryNotificationInput = {
  inventoryCondition: NotificationCondition;
  inventoryItem: Scalars['String']['input'];
  inventoryType: InventoryEntryType;
  inventoryValue: Scalars['Float']['input'];
};

export type LegacyPlantingStatus = {
  __typename?: 'LegacyPlantingStatus';
  status?: Maybe<PlantingStatuses>;
};

export type LineItemsFilterInput = {
  cropConversion?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Location = {
  __typename?: 'Location';
  acres?: Maybe<Scalars['Float']['output']>;
  canDelete: Scalars['Boolean']['output'];
  certificationStatus?: Maybe<CertificationStatus>;
  coordinates?: Maybe<Coordinates>;
  createdAt: Scalars['DateTime']['output'];
  hasPlanting?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  plantings?: Maybe<Array<Maybe<Planting>>>;
  site?: Maybe<Site>;
  siteId?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<PlantingLocationType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type LocationBatchCreateInput = {
  locations: Array<InputMaybe<LocationCreateInput>>;
};

export type LocationConnection = {
  __typename?: 'LocationConnection';
  edges: Array<Maybe<LocationEdge>>;
  pageInfo: PageInfo;
};

export type LocationCoordinates = {
  __typename?: 'LocationCoordinates';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type LocationCoordinatesInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type LocationCreateInput = {
  acres?: InputMaybe<Scalars['Float']['input']>;
  certificationStatus?: InputMaybe<CertificationStatus>;
  coordinates?: InputMaybe<CoordinatesInput>;
  name: Scalars['String']['input'];
  siteId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<PlantingLocationType>;
};

export type LocationDeleteBatchInput = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type LocationEdge = {
  __typename?: 'LocationEdge';
  node?: Maybe<Location>;
};

export type LocationFilter = {
  cropId?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type LocationUpdateInput = {
  acres?: InputMaybe<Scalars['Float']['input']>;
  certificationStatus?: InputMaybe<CertificationStatus>;
  coordinates?: InputMaybe<CoordinatesInput>;
  locationId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<PlantingLocationType>;
};

export type Metric = {
  __typename?: 'Metric';
  current: MetricNode;
  name: Scalars['String']['output'];
  precentage: MetricPrecentageNode;
  previous: MetricNode;
};

export type MetricConnection = {
  __typename?: 'MetricConnection';
  avgOrderValue?: Maybe<Metric>;
  data: Array<Maybe<Metric>>;
  numberOfCustomers?: Maybe<Metric>;
  numberOfOrders?: Maybe<Metric>;
  summary: MetricSummary;
};

export type MetricNode = {
  __typename?: 'MetricNode';
  date?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type MetricPrecentageNode = {
  __typename?: 'MetricPrecentageNode';
  status: MetricPrecentageType;
  value: Scalars['Int']['output'];
};

export enum MetricPrecentageType {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE'
}

export type MetricSummary = {
  __typename?: 'MetricSummary';
  current?: Maybe<Scalars['Int']['output']>;
  previous?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCheckoutDiscount: CheckoutOrder;
  addItem: Cart;
  adminViewCreate: AdminView;
  adminViewDelete: AdminViewDeleted;
  adminViewSetSelected?: Maybe<Scalars['Boolean']['output']>;
  authenticateWithApple: AccessTokens;
  authenticateWithCredentials?: Maybe<AccessTokens>;
  authenticateWithFacebook: AccessTokens;
  authenticateWithGoogle: AccessTokens;
  batchDeleteCropInventoryEntry: Array<Maybe<Scalars['ID']['output']>>;
  batchDeleteFertilizer: Scalars['Boolean']['output'];
  batchDeleteFertilizerInventoryEntry: Array<Maybe<Scalars['ID']['output']>>;
  batchDeleteInventoryLocation: Array<Maybe<Scalars['ID']['output']>>;
  batchDeletePesticide: Scalars['Boolean']['output'];
  batchDeletePesticideInventoryEntry: Array<Maybe<Scalars['ID']['output']>>;
  batchDeleteSeed: Array<Maybe<SeedDelete>>;
  batchDeleteSeedInventoryEntry: Array<Maybe<Scalars['ID']['output']>>;
  categoryCreate: Category;
  changePassword: AccessTokens;
  checkoutCreate: CheckoutOrder;
  completeCheckout: CheckoutOrder;
  createCardPaymentMethod: PaymentMethod;
  createCheckoutPaymentMethod: CheckoutOrder;
  createCropInventoryEntry: CropInventoryEntry;
  createFertilizer: Fertilizer;
  createFertilizerInventoryEntry: FertilizerInventoryEntry;
  createInventoryLocation: InventoryLocation;
  createPesticide: Pesticide;
  createPesticideInventoryEntry: PesticideInventoryEntry;
  createPlaidPaymentMethod: PaymentMethod;
  createSeed: Seed;
  createSeedInventoryEntry: SeedInventoryEntry;
  createTag: Tag;
  createTeamMember: User;
  createUser: User;
  createUserAddress?: Maybe<User>;
  cropCreate: Crop;
  cropDelete: Crop;
  cropDeleteBatch: Array<Maybe<CropDelete>>;
  cropUpdate: Crop;
  cropWizard: CropWizardResponse;
  customerAccountCreate: Customer;
  customerAccountDelete: Customer;
  customerAccountUpdate: Customer;
  customerAddressCreate: Customer;
  customerAddressDelete: Customer;
  customerAddressUpdate: Customer;
  customerCreate: Customer;
  customerResetInviteCode: Customer;
  customerUpdate: Customer;
  deleteCardPaymentMethod: PaymentMethod;
  deleteCropInventoryEntry: CropInventoryEntry;
  deleteFertilizer: Scalars['Boolean']['output'];
  deleteFertilizerInventoryEntry: FertilizerInventoryEntry;
  deleteInventoryLocation: InventoryLocation;
  deletePesticide: Scalars['Boolean']['output'];
  deletePesticideInventoryEntry: PesticideInventoryEntry;
  deleteSeed: Seed;
  deleteSeedInventoryEntry: SeedInventoryEntry;
  deleteTag: Tag;
  deleteTeamMember: User;
  deleteUserAddress: User;
  draftAddCustomer: Draft;
  draftAddItem: Draft;
  draftComplete?: Maybe<Order>;
  draftCreate: Draft;
  draftDeleteMany: Array<Maybe<BatchDraft>>;
  draftRemoveCustomer: Draft;
  draftRemoveItem: Draft;
  draftUpdateBillingAddress: Draft;
  draftUpdateDeliveryFee: Draft;
  draftUpdateItem: Draft;
  draftUpdatePaymentMethod: Draft;
  draftUpdateShipping?: Maybe<Draft>;
  draftUpdateShippingAddress: Draft;
  forgotPassword: ForgotPassword;
  locationBatchCreate: Array<Maybe<BatchLocation>>;
  locationCreate: Location;
  locationDelete: Location;
  locationDeleteBatch: Array<Maybe<BatchLocation>>;
  locationUpdate: Location;
  notificationTriggerCreate: NotificationTrigger;
  notificationTriggerDelete: NotificationTrigger;
  notificationTriggerDeleteBatch: NotificationTrigger;
  notificationTriggerUpdate: NotificationTrigger;
  orderAddItem: Order;
  orderBatchCancel: Scalars['Void']['output'];
  orderRemoveItem: Order;
  orderReorder: ReorderedDraftOrder;
  orderSendUpdates: Order;
  orderUpdateBillingAddress: Order;
  orderUpdateCustomer: Order;
  orderUpdateDelivery: Order;
  orderUpdateDeliveryFee: Order;
  orderUpdateItem: Order;
  orderUpdatePackingComplete: Order;
  orderUpdatePackingItems: Order;
  orderUpdatePayment: Order;
  orderUpdatePickup: Order;
  orderUpdateShippingAddress: Order;
  payOrder: Order;
  plantingBatchCreate: PlantingBatchResult;
  plantingCloseMany: Array<Maybe<PlantingId>>;
  plantingCreate: Planting;
  plantingDelete: Planting;
  plantingDeleteBatch: Array<Maybe<PlantingId>>;
  plantingUpdate: Planting;
  productBatchCreate?: Maybe<ProductBatchResult>;
  productCreate?: Maybe<Product>;
  productUpdate?: Maybe<Product>;
  refreshToken: AccessTokens;
  removeItem: Cart;
  setItems: Cart;
  siteCreate: Site;
  siteDelete: Site;
  siteDeleteBatch: Array<Maybe<SiteDelete>>;
  siteUpdate: Site;
  taskCreate: Task;
  taskDelete: Task;
  taskSplit: Task;
  taskUpdate: Task;
  tasksBatchCreateHarvesting: Array<Maybe<Task>>;
  tasksBatchCreateHarvestingFromVariants: Array<Maybe<Task>>;
  tasksCreate: Array<Maybe<Task>>;
  tasksDelete: Array<Maybe<TasksDelete>>;
  templateCreate: Template;
  templateDelete: Template;
  templateDeleteBatch: Array<Maybe<TemplateDelete>>;
  templateUpdate: Template;
  templateWizard: TemplateWizardResponse;
  unitCreate: Unit;
  updateCheckoutBillingAddress: CheckoutOrder;
  updateCheckoutPaymentMethod: CheckoutOrder;
  updateCheckoutShippingAddress: CheckoutOrder;
  updateCheckoutShippingOption: CheckoutOrder;
  updateCropInventoryEntry: CropInventoryEntry;
  updateDefaultCardPayment: PaymentMethod;
  updateFertilizer: Fertilizer;
  updateFertilizerInventoryEntry: FertilizerInventoryEntry;
  updateInventoryLocation: InventoryLocation;
  updateItem: Cart;
  updatePesticide: Pesticide;
  updatePesticideInventoryEntry: PesticideInventoryEntry;
  updateSeed: Seed;
  updateSeedInventoryEntry: SeedInventoryEntry;
  updateTag: Tag;
  updateTeamMember: User;
  updateUser: User;
  updateUserAddress?: Maybe<User>;
  worklogCreate: Worklog;
  worklogDelete?: Maybe<Worklog>;
  worklogUpdate: Worklog;
};


export type MutationAddCheckoutDiscountArgs = {
  input: AddCheckoutDiscountInput;
};


export type MutationAddItemArgs = {
  input: AddToCartInput;
};


export type MutationAdminViewCreateArgs = {
  input: AdminViewCreateInput;
};


export type MutationAdminViewDeleteArgs = {
  input: AdminViewDeleteInput;
};


export type MutationAdminViewSetSelectedArgs = {
  input: AdminViewSetSelectedInput;
};


export type MutationAuthenticateWithAppleArgs = {
  token: Scalars['String']['input'];
};


export type MutationAuthenticateWithCredentialsArgs = {
  password: Scalars['String']['input'];
  type: UserType;
  username: Scalars['String']['input'];
};


export type MutationAuthenticateWithFacebookArgs = {
  token: Scalars['String']['input'];
};


export type MutationAuthenticateWithGoogleArgs = {
  token: Scalars['String']['input'];
};


export type MutationBatchDeleteCropInventoryEntryArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationBatchDeleteFertilizerArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationBatchDeleteFertilizerInventoryEntryArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationBatchDeleteInventoryLocationArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationBatchDeletePesticideArgs = {
  input: BatchDeleteInput;
};


export type MutationBatchDeletePesticideInventoryEntryArgs = {
  input: BatchDeleteInput;
};


export type MutationBatchDeleteSeedArgs = {
  input: BatchDeleteInput;
};


export type MutationBatchDeleteSeedInventoryEntryArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationCategoryCreateArgs = {
  input: CreateCategoryInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationCheckoutCreateArgs = {
  input: CreateCheckoutInput;
};


export type MutationCompleteCheckoutArgs = {
  input: CompleteCheckoutInput;
};


export type MutationCreateCardPaymentMethodArgs = {
  input: CreateCardPaymentMethodInput;
};


export type MutationCreateCheckoutPaymentMethodArgs = {
  input: CreateCheckoutPaymentMethodInput;
};


export type MutationCreateCropInventoryEntryArgs = {
  input: CropInventoryEntryInput;
};


export type MutationCreateFertilizerArgs = {
  input: CreateFertilizerInput;
};


export type MutationCreateFertilizerInventoryEntryArgs = {
  input: FertilizerInventoryEntryInput;
};


export type MutationCreateInventoryLocationArgs = {
  input: CreateInventoryLocationInput;
};


export type MutationCreatePesticideArgs = {
  input: CreatePesticideInput;
};


export type MutationCreatePesticideInventoryEntryArgs = {
  input: CreatePesticideInventoryEntryInput;
};


export type MutationCreatePlaidPaymentMethodArgs = {
  input: CreatePlaidPaymentMethodInput;
};


export type MutationCreateSeedArgs = {
  input: CreateSeedInput;
};


export type MutationCreateSeedInventoryEntryArgs = {
  input: SeedInventoryEntryInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateTeamMemberArgs = {
  input: CreateTeamMemberInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserAddressArgs = {
  input: CreateUserAddressInput;
};


export type MutationCropCreateArgs = {
  input: CropCreateInput;
};


export type MutationCropDeleteArgs = {
  cropId: Scalars['ID']['input'];
};


export type MutationCropDeleteBatchArgs = {
  input: CropDeleteBatchInput;
};


export type MutationCropUpdateArgs = {
  input: CropUpdateInput;
};


export type MutationCropWizardArgs = {
  input: CropWizardInput;
};


export type MutationCustomerAccountCreateArgs = {
  input: CustomerAccountCreateInput;
};


export type MutationCustomerAccountDeleteArgs = {
  input: CustomerAccountDeleteInput;
};


export type MutationCustomerAccountUpdateArgs = {
  input: CustomerAccountUpdateInput;
};


export type MutationCustomerAddressCreateArgs = {
  input: CustomerAddressCreateInput;
};


export type MutationCustomerAddressDeleteArgs = {
  input: CustomerAddressDeleteInput;
};


export type MutationCustomerAddressUpdateArgs = {
  input: CustomerAddressUpdateInput;
};


export type MutationCustomerCreateArgs = {
  input: CustomerCreateInput;
};


export type MutationCustomerResetInviteCodeArgs = {
  input: CustomerResetInviteCodeInput;
};


export type MutationCustomerUpdateArgs = {
  input: CustomerUpdateInput;
};


export type MutationDeleteCardPaymentMethodArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCropInventoryEntryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFertilizerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFertilizerInventoryEntryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInventoryLocationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePesticideArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePesticideInventoryEntryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSeedArgs = {
  seedId: Scalars['ID']['input'];
};


export type MutationDeleteSeedInventoryEntryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTeamMemberArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserAddressArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDraftAddCustomerArgs = {
  input: DraftAddCustomerInput;
};


export type MutationDraftAddItemArgs = {
  input: DraftAddItemInput;
};


export type MutationDraftCompleteArgs = {
  draftId: Scalars['ID']['input'];
};


export type MutationDraftDeleteManyArgs = {
  input: DraftDeleteManyInput;
};


export type MutationDraftRemoveCustomerArgs = {
  draftId: Scalars['ID']['input'];
};


export type MutationDraftRemoveItemArgs = {
  input: DraftRemoveItemInput;
};


export type MutationDraftUpdateBillingAddressArgs = {
  input: DraftUpdateBillingAddressInput;
};


export type MutationDraftUpdateDeliveryFeeArgs = {
  input: DraftUpdateDeliveryFeeInput;
};


export type MutationDraftUpdateItemArgs = {
  input: DraftUpdateItemInput;
};


export type MutationDraftUpdatePaymentMethodArgs = {
  input: DraftUpdatePaymentMethodInput;
};


export type MutationDraftUpdateShippingArgs = {
  input: DraftUpdateShippingInput;
};


export type MutationDraftUpdateShippingAddressArgs = {
  input: DraftUpdateShippingAddressInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationLocationBatchCreateArgs = {
  input: LocationBatchCreateInput;
};


export type MutationLocationCreateArgs = {
  input: LocationCreateInput;
};


export type MutationLocationDeleteArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationLocationDeleteBatchArgs = {
  input: LocationDeleteBatchInput;
};


export type MutationLocationUpdateArgs = {
  input: LocationUpdateInput;
};


export type MutationNotificationTriggerCreateArgs = {
  input: NotificationTriggerCreateInput;
};


export type MutationNotificationTriggerDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationNotificationTriggerDeleteBatchArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationNotificationTriggerUpdateArgs = {
  input: NotificationTriggerUpdateInput;
};


export type MutationOrderAddItemArgs = {
  input: OrderAddItemInput;
};


export type MutationOrderBatchCancelArgs = {
  input: OrderBatchCancelInput;
};


export type MutationOrderRemoveItemArgs = {
  input: OrderRemoveItemInput;
};


export type MutationOrderReorderArgs = {
  input: OrderReorderInput;
};


export type MutationOrderSendUpdatesArgs = {
  input: OrderSendUpdatesInput;
};


export type MutationOrderUpdateBillingAddressArgs = {
  input: OrderUpdateBillingAddressInput;
};


export type MutationOrderUpdateCustomerArgs = {
  input: OrderUpdateCustomerInput;
};


export type MutationOrderUpdateDeliveryArgs = {
  input: OrderUpdateDeliveryInput;
};


export type MutationOrderUpdateDeliveryFeeArgs = {
  input: OrderUpdateDeliveryFeeInput;
};


export type MutationOrderUpdateItemArgs = {
  input: OrderUpdateItemInput;
};


export type MutationOrderUpdatePackingCompleteArgs = {
  input: OrderUpdatePackingCompleteInput;
};


export type MutationOrderUpdatePackingItemsArgs = {
  input: OrderUpdatePackingItemsInput;
};


export type MutationOrderUpdatePaymentArgs = {
  input: OrderUpdatePaymentInput;
};


export type MutationOrderUpdatePickupArgs = {
  input: OrderUpdatePickupInput;
};


export type MutationOrderUpdateShippingAddressArgs = {
  input: OrderUpdateShippingAddressInput;
};


export type MutationPayOrderArgs = {
  input: PayOrderInput;
};


export type MutationPlantingBatchCreateArgs = {
  input: PlantingBatchCreateInput;
};


export type MutationPlantingCloseManyArgs = {
  input: PlantingBatchInput;
};


export type MutationPlantingCreateArgs = {
  input: PlantingCreateInput;
};


export type MutationPlantingDeleteArgs = {
  plantingId: Scalars['ID']['input'];
};


export type MutationPlantingDeleteBatchArgs = {
  input: PlantingBatchInput;
};


export type MutationPlantingUpdateArgs = {
  input: PlantingUpdateInput;
};


export type MutationProductBatchCreateArgs = {
  input: ProductBatchCreateInput;
};


export type MutationProductCreateArgs = {
  input: ProductCreateInput;
};


export type MutationProductUpdateArgs = {
  input: ProductUpdateInput;
};


export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationRemoveItemArgs = {
  input: RemoveCartItemInput;
};


export type MutationSetItemsArgs = {
  input: SetCartItemsInput;
};


export type MutationSiteCreateArgs = {
  input: SiteCreateInput;
};


export type MutationSiteDeleteArgs = {
  siteId: Scalars['ID']['input'];
};


export type MutationSiteDeleteBatchArgs = {
  input: SiteDeleteBatchInput;
};


export type MutationSiteUpdateArgs = {
  input: SiteUpdateInput;
};


export type MutationTaskCreateArgs = {
  input: CreateTaskInput;
};


export type MutationTaskDeleteArgs = {
  taskId: Scalars['ID']['input'];
};


export type MutationTaskSplitArgs = {
  input: SplitTaskInput;
};


export type MutationTaskUpdateArgs = {
  input: UpdateTaskInput;
};


export type MutationTasksBatchCreateHarvestingArgs = {
  input: BatchCreateHarvestingTasksInput;
};


export type MutationTasksBatchCreateHarvestingFromVariantsArgs = {
  input: BatchCreateHarvestingTasksFromVariantsInput;
};


export type MutationTasksCreateArgs = {
  input: CreateTasksInput;
};


export type MutationTasksDeleteArgs = {
  input: DeleteTasksInput;
};


export type MutationTemplateCreateArgs = {
  input: TemplateCreateInput;
};


export type MutationTemplateDeleteArgs = {
  templateId: Scalars['ID']['input'];
};


export type MutationTemplateDeleteBatchArgs = {
  input: TemplateDeleteBatchInput;
};


export type MutationTemplateUpdateArgs = {
  input: TemplateUpdateInput;
};


export type MutationTemplateWizardArgs = {
  input: TemplateWizardInput;
};


export type MutationUnitCreateArgs = {
  input: UnitCreateInput;
};


export type MutationUpdateCheckoutBillingAddressArgs = {
  input: UpdateCheckoutBillingAddressInput;
};


export type MutationUpdateCheckoutPaymentMethodArgs = {
  input: UpdateCheckoutPaymentMethodInput;
};


export type MutationUpdateCheckoutShippingAddressArgs = {
  input: UpdateCheckoutShippingAddressInput;
};


export type MutationUpdateCheckoutShippingOptionArgs = {
  input: UpdateCheckoutShippingOptionInput;
};


export type MutationUpdateCropInventoryEntryArgs = {
  id: Scalars['ID']['input'];
  input: CropInventoryEntryInput;
};


export type MutationUpdateDefaultCardPaymentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateFertilizerArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFertilizerInput;
};


export type MutationUpdateFertilizerInventoryEntryArgs = {
  id: Scalars['ID']['input'];
  input: FertilizerInventoryEntryInput;
};


export type MutationUpdateInventoryLocationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateInventoryLocationInput;
};


export type MutationUpdateItemArgs = {
  input: UpdateCartItemInput;
};


export type MutationUpdatePesticideArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePesticideInput;
};


export type MutationUpdatePesticideInventoryEntryArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePesticideInventoryEntryInput;
};


export type MutationUpdateSeedArgs = {
  input: UpdateSeedInput;
};


export type MutationUpdateSeedInventoryEntryArgs = {
  id: Scalars['ID']['input'];
  input: SeedInventoryEntryInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationUpdateTeamMemberArgs = {
  input: UpdateTeamMemberInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserAddressArgs = {
  input: UpdateUserAddressInput;
};


export type MutationWorklogCreateArgs = {
  input: WorklogInput;
};


export type MutationWorklogDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationWorklogUpdateArgs = {
  id: Scalars['ID']['input'];
  input: WorklogUpdateInput;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  account?: Maybe<Account>;
  accountId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  fertilizerInventory?: Maybe<FertilizerInventoryEntry>;
  id: Scalars['ID']['output'];
  inventoryId: Scalars['ID']['output'];
  order?: Maybe<Order>;
  orderId: Scalars['ID']['output'];
  pesticideInventory?: Maybe<PesticideInventoryEntry>;
  producerId: Scalars['ID']['output'];
  seedInventory?: Maybe<SeedInventoryEntry>;
  threshold: Scalars['Float']['output'];
  triggerId?: Maybe<Scalars['ID']['output']>;
  type: NotificationType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum NotificationCondition {
  Above = 'above',
  Below = 'below',
  Is = 'is',
  IsNot = 'isNot'
}

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<Maybe<NotificationEdge>>;
  pageInfo: PageInfo;
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  node?: Maybe<Notification>;
};

export type NotificationFilter = {
  type?: InputMaybe<NotificationType>;
};

export type NotificationTrigger = {
  __typename?: 'NotificationTrigger';
  accountId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inventory?: Maybe<InventoryNotification>;
  order?: Maybe<OrderNotification>;
  producerId: Scalars['ID']['output'];
  triggerAction: TriggerAction;
  type: NotificationType;
  updatedAt: Scalars['DateTime']['output'];
};

export type NotificationTriggerConnection = {
  __typename?: 'NotificationTriggerConnection';
  edges: Array<Maybe<NotificationTriggerEdge>>;
  pageInfo: PageInfo;
};

export type NotificationTriggerCreateInput = {
  accountId: Scalars['ID']['input'];
  inventory?: InputMaybe<InventoryNotificationInput>;
  order?: InputMaybe<OrderNotificationInput>;
  triggerAction: TriggerAction;
  type: NotificationType;
};

export type NotificationTriggerEdge = {
  __typename?: 'NotificationTriggerEdge';
  node?: Maybe<NotificationTrigger>;
};

export type NotificationTriggerFilter = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<NotificationType>;
};

export type NotificationTriggerUpdateInput = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  inventory?: InputMaybe<InventoryNotificationInput>;
  order?: InputMaybe<OrderNotificationInput>;
  triggerAction?: InputMaybe<TriggerAction>;
  type?: InputMaybe<NotificationType>;
};

export enum NotificationType {
  Inventory = 'INVENTORY',
  Order = 'ORDER',
  Plantings = 'PLANTINGS'
}

export type Order = Node & {
  __typename?: 'Order';
  booked?: Maybe<Scalars['Boolean']['output']>;
  canceled?: Maybe<OrderCanceled>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<OrderCreatedBy>;
  customer?: Maybe<OrderCustomer>;
  discountTotal: Scalars['Int']['output'];
  financialStatus: FinancialStatus;
  grandTotal: Scalars['Int']['output'];
  hash: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  intradayNumber?: Maybe<Scalars['Int']['output']>;
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  lineItems: Array<Maybe<OrderLineItem>>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  packed?: Maybe<OrderPacked>;
  payment: Payment;
  producer: Producer;
  receivedAt?: Maybe<Scalars['DateTime']['output']>;
  refundedTotal: Scalars['Int']['output'];
  serviceFee: Scalars['Int']['output'];
  shipping: OrderShipping;
  shippingAddress?: Maybe<OrderShippingAddress>;
  shippingTotal: Scalars['Int']['output'];
  source?: Maybe<Scalars['String']['output']>;
  status: OrderStatus;
  subTotal: Scalars['Int']['output'];
  taxTotal: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrderAddItemInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['PositiveInt']['input'];
  variantId: Scalars['ID']['input'];
};

export type OrderBatchCancelInput = {
  orderIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type OrderCanceled = {
  __typename?: 'OrderCanceled';
  at?: Maybe<Scalars['DateTime']['output']>;
  by?: Maybe<OrderCanceledBy>;
};

export type OrderCanceledBy = {
  __typename?: 'OrderCanceledBy';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  edges: Array<Maybe<OrderEdge>>;
  pageInfo: PageInfo;
};

export type OrderCreatedBy = {
  __typename?: 'OrderCreatedBy';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type OrderCustomer = {
  __typename?: 'OrderCustomer';
  billingAddress?: Maybe<CustomerAddress>;
  companyName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<CustomerAddress>;
  type: CustomerType;
};

export type OrderDeliveryCompanyBy = {
  __typename?: 'OrderDeliveryCompanyBy';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type OrderDeliveryRoute = {
  __typename?: 'OrderDeliveryRoute';
  arrivalTime?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  node: Order;
};

export type OrderFilterInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  isPacked?: InputMaybe<Scalars['Boolean']['input']>;
  shippingFrom?: InputMaybe<Scalars['DateTime']['input']>;
  shippingTo?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrderLineItem = Node & {
  __typename?: 'OrderLineItem';
  discountTotal: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  lotNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  orderedQuantity?: Maybe<Scalars['Float']['output']>;
  packed?: Maybe<Scalars['Boolean']['output']>;
  packedBy?: Maybe<Account>;
  packedQuantity?: Maybe<Scalars['Float']['output']>;
  packingEntryItems?: Maybe<Array<PackingEntryItem>>;
  price: Scalars['Float']['output'];
  quantity: Scalars['PositiveInt']['output'];
  rawQuantity?: Maybe<Scalars['Float']['output']>;
  rawUnitQuantity?: Maybe<Scalars['Float']['output']>;
  unit: Scalars['String']['output'];
  unitPrice: Scalars['Float']['output'];
  unitQuantity: Scalars['PositiveInt']['output'];
  variant?: Maybe<Scalars['String']['output']>;
  variantId?: Maybe<Scalars['ID']['output']>;
};

export type OrderLineItemConnection = {
  __typename?: 'OrderLineItemConnection';
  edges: Array<Maybe<OrderLineItemEdge>>;
  pageInfo: PageInfo;
};

export type OrderLineItemEdge = {
  __typename?: 'OrderLineItemEdge';
  node?: Maybe<OrderLineItem>;
};

export type OrderLineItemImage = {
  __typename?: 'OrderLineItemImage';
  alt: Scalars['String']['output'];
  base64?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  src: Scalars['String']['output'];
};

export type OrderNotification = {
  __typename?: 'OrderNotification';
  orderCondition: NotificationCondition;
  orderType: OrderType;
  orderValue: Scalars['String']['output'];
};

export type OrderNotificationInput = {
  orderCondition: NotificationCondition;
  orderType: OrderType;
  orderValue: Scalars['String']['input'];
};

export type OrderPacked = {
  __typename?: 'OrderPacked';
  at?: Maybe<Scalars['DateTime']['output']>;
  by?: Maybe<OrderPackedBy>;
};

export type OrderPackedBy = {
  __typename?: 'OrderPackedBy';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum OrderPaymentMethod {
  Card = 'CARD',
  Check = 'CHECK',
  Cod = 'COD',
  Invoice = 'INVOICE'
}

export type OrderRemoveItemInput = {
  itemId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

export type OrderReorderInput = {
  orderId: Scalars['ID']['input'];
};

export type OrderSendUpdatesInput = {
  orderId: Scalars['ID']['input'];
  updatedInvoice: Scalars['Boolean']['input'];
  updatedOrder: Scalars['Boolean']['input'];
};

export type OrderShipping = {
  __typename?: 'OrderShipping';
  date?: Maybe<Scalars['DateTime']['output']>;
  deliveryCompany?: Maybe<OrderdeliveryCompany>;
  fee?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<ProducerLocation>;
  shippingAddress?: Maybe<Scalars['String']['output']>;
  type: OrderShippingType;
};

export type OrderShippingAddress = {
  __typename?: 'OrderShippingAddress';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  fee?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['Latitude']['output']>;
  longitude?: Maybe<Scalars['Longitude']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export enum OrderShippingType {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP'
}

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  New = 'NEW',
  NotApproved = 'NOT_APPROVED',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  Packed = 'PACKED',
  Received = 'RECEIVED'
}

export enum OrderType {
  Category = 'category',
  Status = 'status'
}

export type OrderUpdateBillingAddressInput = {
  billingAddressId: Scalars['ID']['input'];
  customerId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

export type OrderUpdateCustomerInput = {
  customerId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

export type OrderUpdateDeliveryFeeInput = {
  fee: Scalars['Int']['input'];
  orderId: Scalars['ID']['input'];
};

export type OrderUpdateDeliveryInput = {
  deliveryCompanyId: Scalars['ID']['input'];
  deliveryDate: Scalars['DateTime']['input'];
  orderId: Scalars['ID']['input'];
};

export type OrderUpdateItemInput = {
  itemId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
  quantity?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export type OrderUpdatePackingCompleteInput = {
  completed: Scalars['Boolean']['input'];
  orderId: Scalars['ID']['input'];
};

export type OrderUpdatePackingEntryItemInput = {
  id: Scalars['ID']['input'];
  lotNumber?: InputMaybe<Scalars['String']['input']>;
  packedQuantity: Scalars['Float']['input'];
};

export type OrderUpdatePackingItemInput = {
  id: Scalars['ID']['input'];
  lotNumber?: InputMaybe<Scalars['String']['input']>;
  packed: Scalars['Boolean']['input'];
  packingEntryItems?: InputMaybe<Array<OrderUpdatePackingEntryItemInput>>;
};

export type OrderUpdatePackingItemsInput = {
  lineItems?: InputMaybe<Array<OrderUpdatePackingItemInput>>;
  orderId: Scalars['ID']['input'];
};

export type OrderUpdatePaymentInput = {
  method: OrderPaymentMethod;
  orderId: Scalars['ID']['input'];
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
};

export type OrderUpdatePickupInput = {
  deliveryDate: Scalars['DateTime']['input'];
  locationId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

export type OrderUpdateShippingAddressInput = {
  customerId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
  shippingAddressId: Scalars['ID']['input'];
};

export type OrderdeliveryCompany = {
  __typename?: 'OrderdeliveryCompany';
  by?: Maybe<OrderDeliveryCompanyBy>;
  deliveryRoute?: Maybe<OrderDeliveryRoute>;
  handle?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  outForDeliveryAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PackingEntry = {
  __typename?: 'PackingEntry';
  id: Scalars['ID']['output'];
  lotNumber?: Maybe<Scalars['String']['output']>;
  packedQuantity: Scalars['Float']['output'];
};

export type PackingEntryItem = {
  __typename?: 'PackingEntryItem';
  by?: Maybe<Account>;
  id: Scalars['ID']['output'];
  packingEntry?: Maybe<PackingEntry>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage?: Maybe<Scalars['Int']['output']>;
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  pageCount?: Maybe<Scalars['Int']['output']>;
  startCursor?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PayOrderInput = {
  hash: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
  paymentMethodId: Scalars['ID']['input'];
};

export type Payment = {
  __typename?: 'Payment';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  initiatedAt?: Maybe<Scalars['DateTime']['output']>;
  method?: Maybe<PaymentMethod>;
  payoutAvailableOn?: Maybe<Scalars['DateTime']['output']>;
  type: PaymentType;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  brand?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PaymentMethodType>;
};

export enum PaymentMethodType {
  Cash = 'CASH',
  Check = 'CHECK',
  Cod = 'COD',
  CouponFmnp = 'COUPON_FMNP',
  CouponFreshconnect = 'COUPON_FRESHCONNECT',
  CouponGreenmarketBucks = 'COUPON_GREENMARKET_BUCKS',
  CouponHealthBucks = 'COUPON_HEALTH_BUCKS',
  CouponSnap = 'COUPON_SNAP',
  External = 'EXTERNAL',
  Invoice = 'INVOICE',
  StripeAch = 'STRIPE_ACH',
  StripeCard = 'STRIPE_CARD',
  StripeCardPaymentIntent = 'STRIPE_CARD_PAYMENT_INTENT',
  StripeCardPayworks = 'STRIPE_CARD_PAYWORKS',
  SubscriptionUpfront = 'SUBSCRIPTION_UPFRONT',
  Unknown = 'UNKNOWN'
}

export enum PaymentType {
  Direct = 'DIRECT',
  External = 'EXTERNAL',
  Invoice = 'INVOICE',
  Prepurchase = 'PREPURCHASE',
  Subscription = 'SUBSCRIPTION'
}

export type Pesticide = {
  __typename?: 'Pesticide';
  applicationRate?: Maybe<Scalars['Float']['output']>;
  concentration?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inventoryEntries: Array<PesticideInventoryEntry>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PesticideConnection = {
  __typename?: 'PesticideConnection';
  edges: Array<Maybe<PesticideEdge>>;
  pageInfo: PageInfo;
};

export type PesticideEdge = {
  __typename?: 'PesticideEdge';
  node?: Maybe<Pesticide>;
};

export type PesticideFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PesticideInventoryEntry = {
  __typename?: 'PesticideInventoryEntry';
  container?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<InventoryLocation>;
  locationId?: Maybe<Scalars['ID']['output']>;
  lotNumber?: Maybe<Scalars['String']['output']>;
  pesticide?: Maybe<Pesticide>;
  pesticideId?: Maybe<Scalars['ID']['output']>;
  purchaseDate?: Maybe<Scalars['DateTime']['output']>;
  purchaseLotNumber?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  supplier?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unit: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<InventoryEntryUser>;
};

export type PesticideInventoryEntryConnection = {
  __typename?: 'PesticideInventoryEntryConnection';
  edges: Array<Maybe<PesticideInventoryEntryEdge>>;
  pageInfo: PageInfo;
};

export type PesticideInventoryEntryEdge = {
  __typename?: 'PesticideInventoryEntryEdge';
  node?: Maybe<PesticideInventoryEntry>;
};

export type PesticideInventoryEntryFilter = {
  createdAt?: InputMaybe<SearchDateRange>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  pesticideId?: InputMaybe<Scalars['ID']['input']>;
};

export type PesticideInventoryEntryInput = {
  container?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  pesticideId?: InputMaybe<Scalars['ID']['input']>;
  purchaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  purchaseLotNumber?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Float']['input'];
  supplier?: InputMaybe<Scalars['String']['input']>;
  unit: Scalars['String']['input'];
  user?: InputMaybe<InventoryEntryUserInput>;
};

export enum PesticideUnitTypes {
  Lb = 'lb'
}

export type Planting = Node & {
  __typename?: 'Planting';
  accumulatedGrowingDegreeDays?: Maybe<Scalars['Float']['output']>;
  acres?: Maybe<Scalars['Float']['output']>;
  actualFieldPlantDate?: Maybe<Scalars['DateTime']['output']>;
  bedFeet?: Maybe<Scalars['Float']['output']>;
  bedFeetOverride?: Maybe<Scalars['Float']['output']>;
  bedWidth?: Maybe<Scalars['Float']['output']>;
  bedWidthOverride?: Maybe<Scalars['Float']['output']>;
  cellsPerContainerTray1?: Maybe<Scalars['Int']['output']>;
  cellsPerContainerTray1Override?: Maybe<Scalars['Int']['output']>;
  cellsPerContainerTray2?: Maybe<Scalars['Int']['output']>;
  cellsPerContainerTray2Override?: Maybe<Scalars['Int']['output']>;
  closed?: Maybe<Scalars['Boolean']['output']>;
  computed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crop?: Maybe<Crop>;
  daysToMaturity?: Maybe<Scalars['Float']['output']>;
  endHarvestDate?: Maybe<Scalars['DateTime']['output']>;
  estimatedQuantityPerWeek?: Maybe<Scalars['Float']['output']>;
  fieldPlantDate?: Maybe<Scalars['DateTime']['output']>;
  fieldPlantDateOverride?: Maybe<Scalars['DateTime']['output']>;
  harvestDate?: Maybe<Scalars['DateTime']['output']>;
  harvestWeeks?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  inputType?: Maybe<InputType>;
  inputValue?: Maybe<Scalars['Float']['output']>;
  location?: Maybe<Location>;
  locationOverride?: Maybe<Location>;
  parentId?: Maybe<Scalars['ID']['output']>;
  potUpDate?: Maybe<Scalars['DateTime']['output']>;
  potUpDateOverride?: Maybe<Scalars['DateTime']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  quantityPerWeek?: Maybe<Scalars['Float']['output']>;
  remainingGrowingDegreeDays?: Maybe<Scalars['Float']['output']>;
  rowsPerBed?: Maybe<Scalars['Int']['output']>;
  rowsPerBedOverride?: Maybe<Scalars['Int']['output']>;
  seedingDate?: Maybe<Scalars['DateTime']['output']>;
  seedingDateOverride?: Maybe<Scalars['DateTime']['output']>;
  seeds?: Maybe<Scalars['Float']['output']>;
  seedsPerCellTray1?: Maybe<Scalars['Int']['output']>;
  seedsPerCellTray1Override?: Maybe<Scalars['Int']['output']>;
  site?: Maybe<Site>;
  siteOverride?: Maybe<Site>;
  status?: Maybe<LegacyPlantingStatus>;
  tag?: Maybe<Tag>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  template?: Maybe<Template>;
  transplantDate?: Maybe<Scalars['DateTime']['output']>;
  transplantDateOverride?: Maybe<Scalars['DateTime']['output']>;
  transplantsPerCellTray2?: Maybe<Scalars['Int']['output']>;
  transplantsPerCellTray2Override?: Maybe<Scalars['Int']['output']>;
  trays?: Maybe<Scalars['Float']['output']>;
  trays2?: Maybe<Scalars['Float']['output']>;
  traysOriginal?: Maybe<Scalars['Float']['output']>;
  traysTray1Override?: Maybe<Scalars['Float']['output']>;
  traysTray2Override?: Maybe<Scalars['Float']['output']>;
  variant?: Maybe<CropVariant>;
  variantId?: Maybe<Scalars['ID']['output']>;
  variantIdOverride?: Maybe<Scalars['ID']['output']>;
  weeklyHarvest?: Maybe<Array<Maybe<WeeklyHarvest>>>;
  withinRowSpacing?: Maybe<Scalars['Float']['output']>;
  withinRowSpacingOverride?: Maybe<Scalars['Float']['output']>;
  worklogs?: Maybe<Array<Maybe<Worklog>>>;
};

export type PlantingBatchCreateInput = {
  plantings: Array<InputMaybe<PlantingCreateInput>>;
};

export type PlantingBatchInput = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type PlantingBatchResult = {
  __typename?: 'PlantingBatchResult';
  ids?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type PlantingConnection = {
  __typename?: 'PlantingConnection';
  edges: Array<Maybe<PlantingEdge>>;
  pageInfo: PageInfo;
};

export type PlantingCreateInput = {
  cropId: Scalars['ID']['input'];
  inputType?: InputMaybe<InputType>;
  inputValue?: InputMaybe<Scalars['Float']['input']>;
  interval?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  repeat?: InputMaybe<Scalars['Int']['input']>;
  seedingDate?: InputMaybe<Scalars['DateTime']['input']>;
  tagId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  variantId?: InputMaybe<Scalars['ID']['input']>;
};

export type PlantingEdge = {
  __typename?: 'PlantingEdge';
  node?: Maybe<Planting>;
};

export type PlantingFilterInput = {
  cropId?: InputMaybe<Scalars['String']['input']>;
  cropName?: InputMaybe<Scalars['String']['input']>;
  fieldPlantDate?: InputMaybe<SearchDateRange>;
  harvestDate?: InputMaybe<SearchDateRange>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  seedingDate?: InputMaybe<SearchDateRange>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<PlantingStatus>>>;
  subCategory?: InputMaybe<Scalars['String']['input']>;
  tagId?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
};

export type PlantingId = {
  __typename?: 'PlantingId';
  id: Scalars['ID']['output'];
};

export enum PlantingLocationType {
  Field = 'field',
  HighTunnel = 'high_tunnel',
  LowTunnel = 'low_tunnel',
  Planning = 'planning'
}

export enum PlantingStatus {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export enum PlantingStatuses {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export type PlantingUpdateInput = {
  cellsPerContainerTray1?: InputMaybe<Scalars['Int']['input']>;
  cellsPerContainerTray1Override?: InputMaybe<Scalars['Int']['input']>;
  cellsPerContainerTray2?: InputMaybe<Scalars['Int']['input']>;
  cellsPerContainerTray2Override?: InputMaybe<Scalars['Int']['input']>;
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  cropId?: InputMaybe<Scalars['ID']['input']>;
  endHarvestDateOverride?: InputMaybe<Scalars['DateTime']['input']>;
  harvestDateOverride?: InputMaybe<Scalars['DateTime']['input']>;
  inputType?: InputMaybe<InputType>;
  inputValue?: InputMaybe<Scalars['Float']['input']>;
  interval?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationIdOverride?: InputMaybe<Scalars['ID']['input']>;
  plantingId: Scalars['ID']['input'];
  repeat?: InputMaybe<Scalars['Int']['input']>;
  seedingDate?: InputMaybe<Scalars['DateTime']['input']>;
  seedingDateOverride?: InputMaybe<Scalars['DateTime']['input']>;
  seedsPerCellTray1?: InputMaybe<Scalars['Int']['input']>;
  seedsPerCellTray1Override?: InputMaybe<Scalars['Int']['input']>;
  siteIdOverride?: InputMaybe<Scalars['ID']['input']>;
  tagId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  transplantDate?: InputMaybe<Scalars['DateTime']['input']>;
  transplantsPerCellTray2?: InputMaybe<Scalars['Int']['input']>;
  transplantsPerCellTray2Override?: InputMaybe<Scalars['Int']['input']>;
  trays?: InputMaybe<Scalars['Float']['input']>;
  trays2?: InputMaybe<Scalars['Float']['input']>;
  traysTray1Override?: InputMaybe<Scalars['Float']['input']>;
  traysTray2Override?: InputMaybe<Scalars['Float']['input']>;
  variantId?: InputMaybe<Scalars['ID']['input']>;
  variantIdOverride?: InputMaybe<Scalars['ID']['input']>;
  weeklyHarvest?: InputMaybe<Array<InputMaybe<WeeklyHarvestInput>>>;
};

export enum PriceLevel {
  Retail = 'retail',
  Wholesale = 'wholesale'
}

export type Producer = {
  __typename?: 'Producer';
  accounts?: Maybe<Array<Maybe<Account>>>;
  address1?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  description?: Maybe<ProducerDescription>;
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  latitude?: Maybe<Scalars['Latitude']['output']>;
  logo?: Maybe<Image>;
  longitude?: Maybe<Scalars['Longitude']['output']>;
  name: Scalars['String']['output'];
  products: ProductConnection;
  province?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  zip?: Maybe<Scalars['PostalCode']['output']>;
};


export type ProducerProductsArgs = {
  level?: InputMaybe<PriceLevel>;
  options?: InputMaybe<ProductOptionsInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProducerAccounts = {
  __typename?: 'ProducerAccounts';
  accounts?: Maybe<Array<Maybe<Account>>>;
  id: Scalars['ID']['output'];
};

export type ProducerConnection = {
  __typename?: 'ProducerConnection';
  edges: Array<Maybe<ProducerEdge>>;
  pageInfo: PageInfo;
};

export type ProducerDeliveryCompanies = {
  __typename?: 'ProducerDeliveryCompanies';
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ProducerDeliveryCompaniesConnection = {
  __typename?: 'ProducerDeliveryCompaniesConnection';
  edges: Array<Maybe<ProducerDeliveryCompaniesEdge>>;
};

export type ProducerDeliveryCompaniesEdge = {
  __typename?: 'ProducerDeliveryCompaniesEdge';
  node?: Maybe<ProducerDeliveryCompanies>;
};

export type ProducerDescription = {
  __typename?: 'ProducerDescription';
  retail?: Maybe<Scalars['String']['output']>;
  wholesale?: Maybe<Scalars['String']['output']>;
};

export type ProducerEdge = {
  __typename?: 'ProducerEdge';
  node?: Maybe<Producer>;
};

export type ProducerLocation = {
  __typename?: 'ProducerLocation';
  address1: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<DistanceType>;
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  latitude?: Maybe<Scalars['Latitude']['output']>;
  longitude?: Maybe<Scalars['Longitude']['output']>;
  marketEnd?: Maybe<Scalars['Int']['output']>;
  marketStart?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  pickupEnd?: Maybe<Scalars['Int']['output']>;
  pickupStart?: Maybe<Scalars['Int']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  until?: Maybe<Scalars['DateTime']['output']>;
  zip?: Maybe<Scalars['PostalCode']['output']>;
};

export type ProducerLocationConnection = {
  __typename?: 'ProducerLocationConnection';
  edges: Array<Maybe<ProducerLocationEdge>>;
};

export type ProducerLocationEdge = {
  __typename?: 'ProducerLocationEdge';
  node?: Maybe<ProducerLocation>;
};

export type Product = Node & {
  __typename?: 'Product';
  active: Scalars['Boolean']['output'];
  archived: Scalars['Boolean']['output'];
  category?: Maybe<Category>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  name: Scalars['String']['output'];
  producer?: Maybe<Producer>;
  slug?: Maybe<Scalars['String']['output']>;
  subCategory?: Maybe<SubCategory>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variants?: Maybe<Array<ProductVariant>>;
};

export type ProductBatchCreateInput = {
  products: Array<InputMaybe<ProductBatchCreateInputData>>;
};

export type ProductBatchCreateInputData = {
  category: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  retailPrice: Scalars['Float']['input'];
  subCategory?: InputMaybe<Scalars['String']['input']>;
  unit: Scalars['String']['input'];
  variantName: Scalars['String']['input'];
  wholesalePrice: Scalars['Float']['input'];
};

export type ProductBatchResult = {
  __typename?: 'ProductBatchResult';
  ids?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  edges: Array<Maybe<ProductEdge>>;
  pageInfo: PageInfo;
};

export type ProductCreateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<ProductImageInput>;
  name: Scalars['String']['input'];
  subCategoryId?: InputMaybe<Scalars['String']['input']>;
  variants?: InputMaybe<Array<ProductCreateVariantInput>>;
};

export type ProductCreateVariantInput = {
  active: ProductVariantActiveInput;
  name: Scalars['String']['input'];
  price?: InputMaybe<ProductVariantPriceInput>;
  unitId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProductCropConversion = {
  __typename?: 'ProductCropConversion';
  conversionMultiple: Scalars['Float']['output'];
  crop: Crop;
  cropVariant?: Maybe<CropVariant>;
  harvestingUnit: Scalars['String']['output'];
};

export type ProductCropConversionInput = {
  conversionMultiple: Scalars['Float']['input'];
  cropId: Scalars['ID']['input'];
  cropVariantId?: InputMaybe<Scalars['ID']['input']>;
  harvestingUnit: Scalars['String']['input'];
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  node?: Maybe<Product>;
};

export type ProductImageInput = {
  base64: Scalars['String']['input'];
  blurhash: Scalars['String']['input'];
  src: Scalars['String']['input'];
};

export type ProductOptionsInput = {
  active?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  retail?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subCategory?: InputMaybe<Scalars['String']['input']>;
  wholesale?: InputMaybe<Scalars['Boolean']['input']>;
  withImages?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductUnit = {
  __typename?: 'ProductUnit';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  subQuantity?: Maybe<Scalars['Float']['output']>;
  subUnit?: Maybe<Scalars['String']['output']>;
};

export type ProductUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<ProductImageInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  subCategoryId?: InputMaybe<Scalars['String']['input']>;
  variants?: InputMaybe<Array<ProductUpdateVariantInput>>;
};

export type ProductUpdateVariantInput = {
  active: ProductVariantActiveInput;
  crops?: InputMaybe<Array<InputMaybe<ProductCropConversionInput>>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<ProductVariantPriceInput>;
  unitId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  active: ProductVariantActive;
  crops?: Maybe<Array<ProductCropConversion>>;
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<VariantPrice>;
  type: ProductVariantType;
  unit?: Maybe<ProductUnit>;
  unitQuantity?: Maybe<Scalars['Int']['output']>;
};

export type ProductVariantActive = {
  __typename?: 'ProductVariantActive';
  retail?: Maybe<Scalars['Boolean']['output']>;
  wholesale?: Maybe<Scalars['Boolean']['output']>;
};

export type ProductVariantActiveInput = {
  retail: Scalars['Boolean']['input'];
  wholesale: Scalars['Boolean']['input'];
};

export type ProductVariantPriceInput = {
  retail: Scalars['Int']['input'];
  wholesale: Scalars['Int']['input'];
};

export enum ProductVariantType {
  Custom = 'custom',
  Regular = 'regular',
  Subscription = 'subscription',
  VariantBased = 'variant_based'
}

export type Query = {
  __typename?: 'Query';
  adminViews: Array<AdminView>;
  aggregatedPlantings: AggregatedPlantingConnection;
  cart: Cart;
  carts: CartsConnection;
  categories: CategoryConnection;
  category?: Maybe<Category>;
  checkout: CheckoutOrder;
  checkoutPaymentMethods: Array<CheckoutPaymentMethod>;
  crop: Crop;
  cropInventoryAggregated: CropInventoryEntryConnection;
  cropInventoryCombined: InventoryCombinedConnection;
  cropInventoryEntries: CropInventoryEntryConnection;
  cropInventoryEntry: CropInventoryEntry;
  crops: CropConnection;
  customer: Customer;
  customers: CustomerConnection;
  draft: Draft;
  drafts: DraftConnection;
  fertilizer?: Maybe<Fertilizer>;
  fertilizerInventoryAggregated: FertilizerInventoryEntryConnection;
  fertilizerInventoryEntries: FertilizerInventoryEntryConnection;
  fertilizerInventoryEntry: FertilizerInventoryEntry;
  fertilizers: FertilizerConnection;
  inventoryLocation: InventoryLocation;
  inventoryLocations: InventoryLocationConnection;
  lineItems: AggregatedOrderLineItemConnection;
  location: Location;
  locations: LocationConnection;
  metrics: MetricConnection;
  notification: Notification;
  notificationTrigger: NotificationTrigger;
  notificationTriggers: NotificationTriggerConnection;
  notifications: NotificationConnection;
  order: Order;
  orderItems: Array<Maybe<ReOrderLineItem>>;
  orders: OrderConnection;
  pesticide?: Maybe<Pesticide>;
  pesticideInventoryAggregated: PesticideInventoryEntryConnection;
  pesticideInventoryEntries: PesticideInventoryEntryConnection;
  pesticideInventoryEntry: PesticideInventoryEntry;
  pesticides: PesticideConnection;
  planting: Planting;
  plantings: PlantingConnection;
  plantingsCalendarView: PlantingConnection;
  producer: Producer;
  producerAccounts: ProducerAccounts;
  producerDeliveryCompanies: ProducerDeliveryCompaniesConnection;
  producerLocations: ProducerLocationConnection;
  producers: ProducerConnection;
  product: Product;
  products: ProductConnection;
  search?: Maybe<SearchResults>;
  seed: Seed;
  seedInventoryAggregated: SeedInventoryEntryConnection;
  seedInventoryEntries: SeedInventoryEntryConnection;
  seedInventoryEntry: SeedInventoryEntry;
  seeds: SeedConnection;
  shipping: Array<Maybe<ShippingConnection>>;
  site: Site;
  sites: SiteConnection;
  tags: Array<Tag>;
  task?: Maybe<Task>;
  taskByPlantingId?: Maybe<Task>;
  tasks: TaskConnection;
  tasksByPlantingIdV2: Array<Maybe<Task>>;
  template: Template;
  templates: TemplateConnection;
  units: UnitConnection;
  user?: Maybe<User>;
  variants: Array<Maybe<Variant>>;
  verifyInviteCode: Customer;
  weatherForecasts: WeatherForecastConnection;
  worklog?: Maybe<Worklog>;
  worklogs: WorklogConnection;
};


export type QueryAdminViewsArgs = {
  type: AdminViewType;
};


export type QueryAggregatedPlantingsArgs = {
  filter?: InputMaybe<PlantingFilterInput>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCartArgs = {
  id: Scalars['ID']['input'];
  storeId: Scalars['ID']['input'];
};


export type QueryCartsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCategoriesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCheckoutArgs = {
  id: Scalars['String']['input'];
};


export type QueryCheckoutPaymentMethodsArgs = {
  producerId: Scalars['ID']['input'];
};


export type QueryCropArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCropInventoryAggregatedArgs = {
  filter?: InputMaybe<CropInventoryEntryFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCropInventoryCombinedArgs = {
  filter?: InputMaybe<CropInventoryCombinedFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCropInventoryEntriesArgs = {
  filter?: InputMaybe<CropInventoryEntryFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCropInventoryEntryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCropsArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomersArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDraftArgs = {
  draftId: Scalars['ID']['input'];
};


export type QueryDraftsArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFertilizerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFertilizerInventoryAggregatedArgs = {
  filter?: InputMaybe<FertilizerInventoryEntryFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFertilizerInventoryEntriesArgs = {
  filter?: InputMaybe<FertilizerInventoryEntryFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFertilizerInventoryEntryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFertilizersArgs = {
  filter?: InputMaybe<FertilizerFilterInput>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInventoryLocationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInventoryLocationsArgs = {
  filter?: InputMaybe<InventoryLocationFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLineItemsArgs = {
  filter?: InputMaybe<LineItemsFilterInput>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLocationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLocationsArgs = {
  filter?: InputMaybe<LocationFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMetricsArgs = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};


export type QueryNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNotificationTriggerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNotificationTriggersArgs = {
  filter?: InputMaybe<NotificationTriggerFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNotificationsArgs = {
  filter?: InputMaybe<NotificationFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderItemsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrdersArgs = {
  filter?: InputMaybe<OrderFilterInput>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPesticideArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPesticideInventoryAggregatedArgs = {
  filter?: InputMaybe<PesticideInventoryEntryFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPesticideInventoryEntriesArgs = {
  filter?: InputMaybe<PesticideInventoryEntryFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPesticideInventoryEntryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPesticidesArgs = {
  filter?: InputMaybe<PesticideFilterInput>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPlantingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPlantingsArgs = {
  filter?: InputMaybe<PlantingFilterInput>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPlantingsCalendarViewArgs = {
  filter?: InputMaybe<PlantingFilterInput>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProducerArgs = {
  slug: Scalars['String']['input'];
};


export type QueryProducerAccountsArgs = {
  slug: Scalars['String']['input'];
};


export type QueryProducerDeliveryCompaniesArgs = {
  id: Scalars['String']['input'];
};


export type QueryProducerLocationsArgs = {
  id: Scalars['String']['input'];
};


export type QueryProducersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
  level?: InputMaybe<PriceLevel>;
  options?: InputMaybe<ProductOptionsInput>;
};


export type QueryProductsArgs = {
  level?: InputMaybe<PriceLevel>;
  options?: InputMaybe<ProductOptionsInput>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchArgs = {
  filter?: InputMaybe<SearchFilterInput>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  q: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  type: SearchType;
};


export type QuerySeedArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySeedInventoryAggregatedArgs = {
  filter?: InputMaybe<SeedInventoryEntryFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySeedInventoryEntriesArgs = {
  filter?: InputMaybe<SeedInventoryEntryFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySeedInventoryEntryArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySeedsArgs = {
  filter?: InputMaybe<SeedFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryShippingArgs = {
  producerId: Scalars['ID']['input'];
};


export type QuerySiteArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySitesArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTagsArgs = {
  type?: InputMaybe<TagType>;
};


export type QueryTaskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaskByPlantingIdArgs = {
  plantingId: Scalars['ID']['input'];
};


export type QueryTasksArgs = {
  filter?: InputMaybe<FilterInput>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTasksByPlantingIdV2Args = {
  filter?: InputMaybe<FilterInput>;
  plantingId: Scalars['ID']['input'];
};


export type QueryTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTemplatesArgs = {
  cropId?: InputMaybe<Scalars['ID']['input']>;
  cropName?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUnitsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVerifyInviteCodeArgs = {
  inviteCode: Scalars['String']['input'];
};


export type QueryWeatherForecastsArgs = {
  filter?: InputMaybe<WeatherForecastFilter>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWorklogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorklogsArgs = {
  filter?: InputMaybe<WorklogFilterInput>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ReOrderLineItem = {
  __typename?: 'ReOrderLineItem';
  available: Scalars['Boolean']['output'];
  category: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: OrderLineItemImage;
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  quantity: Scalars['PositiveInt']['output'];
  subCategory: Scalars['String']['output'];
  unitPrice: Scalars['Int']['output'];
  unitQuantity: Scalars['PositiveInt']['output'];
  variant: ReOrderLineItemVariant;
};

export type ReOrderLineItemVariant = {
  __typename?: 'ReOrderLineItemVariant';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: ProductVariantType;
  unit: Scalars['String']['output'];
};

export type RemoveCartItemInput = {
  cartId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  storeId: Scalars['ID']['input'];
};

export type ReorderedDraftOrder = {
  __typename?: 'ReorderedDraftOrder';
  draftId: Scalars['ID']['output'];
};

export enum SearchCustomerType {
  Retail = 'retail',
  Wholesale = 'wholesale'
}

export type SearchDateRange = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum SearchDeliveryType {
  Delivery = 'delivery',
  Pickup = 'pickup'
}

export type SearchFilterInput = {
  customer_type?: InputMaybe<Array<InputMaybe<SearchCustomerType>>>;
  delivery_date?: InputMaybe<SearchDateRange>;
  delivery_type?: InputMaybe<Array<InputMaybe<SearchDeliveryType>>>;
  lastOrderAt?: InputMaybe<SearchDateRange>;
  lot_number?: InputMaybe<Scalars['String']['input']>;
  order_status?: InputMaybe<Array<InputMaybe<SearchOrderStatus>>>;
  packed_at?: InputMaybe<SearchDateRange>;
  payment_status?: InputMaybe<Array<InputMaybe<SearchPaymentStatus>>>;
  source?: InputMaybe<Array<InputMaybe<SearchSource>>>;
  subCategory?: InputMaybe<Scalars['String']['input']>;
};

export enum SearchOffered {
  Retail = 'retail',
  Wholesale = 'wholesale'
}

export enum SearchOrderStatus {
  Cancelled = 'cancelled',
  New = 'new',
  NotApproved = 'not_approved',
  OutForDelivery = 'out_for_delivery',
  Packed = 'packed',
  Received = 'received'
}

export enum SearchPaymentStatus {
  Paid = 'paid',
  Pending = 'pending',
  Unpaid = 'unpaid'
}

export enum SearchProductStatus {
  Active = 'active',
  InActive = 'in_active'
}

export type SearchResultEdge = {
  __typename?: 'SearchResultEdge';
  node?: Maybe<SearchResultNode>;
};

export type SearchResultNode = Category | Crop | Customer | Draft | Location | Order | Producer | Product | Template | Unit;

export type SearchResults = {
  __typename?: 'SearchResults';
  edges?: Maybe<Array<Maybe<SearchResultEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum SearchSource {
  Admin = 'admin',
  Pos = 'pos',
  Storefront = 'storefront'
}

export enum SearchType {
  Category = 'CATEGORY',
  Crop = 'CROP',
  Customer = 'CUSTOMER',
  Draft = 'DRAFT',
  Location = 'LOCATION',
  Order = 'ORDER',
  Producer = 'PRODUCER',
  Product = 'PRODUCT',
  SubCategory = 'SUB_CATEGORY',
  Template = 'TEMPLATE',
  Unit = 'UNIT'
}

export type Seed = Node & {
  __typename?: 'Seed';
  createdAt: Scalars['DateTime']['output'];
  cropName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inventoryEntries?: Maybe<Array<Maybe<SeedInventoryEntry>>>;
  name: Scalars['String']['output'];
  seedsPerUnit?: Maybe<Array<Maybe<SeedsPerUnit>>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SeedConnection = {
  __typename?: 'SeedConnection';
  edges: Array<Maybe<SeedEdge>>;
  pageInfo: PageInfo;
};

export type SeedDelete = {
  __typename?: 'SeedDelete';
  id: Scalars['ID']['output'];
};

export type SeedEdge = {
  __typename?: 'SeedEdge';
  node?: Maybe<Seed>;
};

export type SeedFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SeedInventoryEntry = {
  __typename?: 'SeedInventoryEntry';
  container?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<InventoryLocation>;
  locationId?: Maybe<Scalars['ID']['output']>;
  lotNumber?: Maybe<Scalars['String']['output']>;
  numberOfPackages?: Maybe<Scalars['Float']['output']>;
  packageSize?: Maybe<Scalars['Float']['output']>;
  packageSizeUnit?: Maybe<Scalars['String']['output']>;
  purchaseDate?: Maybe<Scalars['DateTime']['output']>;
  purchaseLotNumber?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  seed?: Maybe<Seed>;
  seedId?: Maybe<Scalars['ID']['output']>;
  supplier?: Maybe<Scalars['String']['output']>;
  unit: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<SeedInventoryEntryUser>;
};

export type SeedInventoryEntryConnection = {
  __typename?: 'SeedInventoryEntryConnection';
  edges: Array<Maybe<SeedInventoryEntryEdge>>;
  pageInfo: PageInfo;
};

export type SeedInventoryEntryEdge = {
  __typename?: 'SeedInventoryEntryEdge';
  node?: Maybe<SeedInventoryEntry>;
};

export type SeedInventoryEntryFilter = {
  createdAt?: InputMaybe<SearchDateRange>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  seedId?: InputMaybe<Scalars['ID']['input']>;
};

export type SeedInventoryEntryInput = {
  container?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  numberOfPackages?: InputMaybe<Scalars['Float']['input']>;
  packageSize?: InputMaybe<Scalars['Float']['input']>;
  packageSizeUnit?: InputMaybe<Scalars['String']['input']>;
  purchaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  purchaseLotNumber?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Float']['input'];
  seedId?: InputMaybe<Scalars['ID']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
  unit: Scalars['String']['input'];
  user?: InputMaybe<SeedInventoryEntryUserInput>;
};

export type SeedInventoryEntryUser = {
  __typename?: 'SeedInventoryEntryUser';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SeedInventoryEntryUserInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export enum SeedUnitTypes {
  Grams = 'grams',
  Kg = 'kg',
  Lb = 'lb',
  M = 'm',
  Oz = 'oz',
  Seeds = 'seeds'
}

export type SeedsPerUnit = {
  __typename?: 'SeedsPerUnit';
  quantity?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type SeedsPerUnitInput = {
  quantity: Scalars['Float']['input'];
  unit: Scalars['String']['input'];
};

export type SetCartItemInput = {
  category: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  subCategory: Scalars['String']['input'];
  type: ProductVariantType;
  unit: Scalars['String']['input'];
  unitQuantity?: InputMaybe<Scalars['Int']['input']>;
  variantName?: InputMaybe<Scalars['String']['input']>;
};

export type SetCartItemsInput = {
  cartId: Scalars['ID']['input'];
  items: Array<SetCartItemInput>;
  storeId: Scalars['ID']['input'];
};

export type Shipping = {
  __typename?: 'Shipping';
  location: ProducerLocation;
  next: Scalars['DateTime']['output'];
  until: Scalars['DateTime']['output'];
};

export type ShippingConnection = {
  __typename?: 'ShippingConnection';
  edges?: Maybe<Array<Maybe<ShippingEdge>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ShippingEdge = {
  __typename?: 'ShippingEdge';
  node: Shipping;
};

export enum ShippingOptionType {
  Delivery = 'delivery',
  Pickup = 'pickup'
}

export enum ShortForecast {
  Cloudy = 'cloudy',
  Drizzle = 'drizzle',
  Foggy = 'foggy',
  Hail = 'hail',
  Hazy = 'hazy',
  MostlyCloudy = 'mostly_cloudy',
  MostlySunny = 'mostly_sunny',
  PartlyCloudy = 'partly_cloudy',
  PartlySunny = 'partly_sunny',
  Rainy = 'rainy',
  Snowy = 'snowy',
  Stormy = 'stormy',
  Sunny = 'sunny',
  Thunderstorm = 'thunderstorm',
  Windy = 'windy'
}

export type Site = {
  __typename?: 'Site';
  coordinates?: Maybe<Coordinates>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locations: Array<Maybe<Location>>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  weatherForecasts?: Maybe<Array<Maybe<WeatherForecast>>>;
};

export type SiteConnection = {
  __typename?: 'SiteConnection';
  edges: Array<Maybe<SiteEdge>>;
  pageInfo: PageInfo;
};

export type SiteCreateInput = {
  coordinates?: InputMaybe<CoordinatesInput>;
  name: Scalars['String']['input'];
};

export type SiteDelete = {
  __typename?: 'SiteDelete';
  id: Scalars['ID']['output'];
};

export type SiteDeleteBatchInput = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type SiteEdge = {
  __typename?: 'SiteEdge';
  node?: Maybe<Site>;
};

export type SiteUpdateInput = {
  coordinates?: InputMaybe<CoordinatesInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
};

export enum Sort {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Source {
  Desktop = 'desktop',
  Mobile = 'mobile'
}

export type SplitTaskInput = {
  currentValue: Scalars['Float']['input'];
  inputType: InputType;
  inputValue: Scalars['Float']['input'];
  taskId: Scalars['ID']['input'];
  trayNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type Store = {
  __typename?: 'Store';
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type SubCategory = {
  __typename?: 'SubCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: TagType;
};

export enum TagColor {
  Beige = 'beige',
  Gray = 'gray',
  Green = 'green',
  Pink = 'pink',
  SubtleGreen = 'subtle_green'
}

export enum TagType {
  Planting = 'planting'
}

export type Task = {
  __typename?: 'Task';
  assignedId?: Maybe<Scalars['String']['output']>;
  bedFeet?: Maybe<Scalars['Float']['output']>;
  bedWidth?: Maybe<Scalars['Float']['output']>;
  cellsPerContainerTray1?: Maybe<Scalars['Int']['output']>;
  cellsPerContainerTray2?: Maybe<Scalars['Int']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedBy?: Maybe<CompletedBy>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crop?: Maybe<Crop>;
  cropVariant?: Maybe<TaskCropVariant>;
  cropVariantId?: Maybe<Scalars['ID']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  fieldPlantDate?: Maybe<Scalars['DateTime']['output']>;
  harvestDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']['output']>;
  lotNumber?: Maybe<Scalars['String']['output']>;
  planting?: Maybe<Planting>;
  potUpDate?: Maybe<Scalars['DateTime']['output']>;
  producer?: Maybe<Producer>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rowsPerBed?: Maybe<Scalars['Int']['output']>;
  seedingDate?: Maybe<Scalars['DateTime']['output']>;
  seeds?: Maybe<Scalars['Float']['output']>;
  seedsPerCellTray1?: Maybe<Scalars['Int']['output']>;
  site?: Maybe<Site>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<TaskStatus>;
  template?: Maybe<Template>;
  transplantDate?: Maybe<Scalars['DateTime']['output']>;
  transplantsPerCellTray2?: Maybe<Scalars['Int']['output']>;
  trays?: Maybe<Scalars['Float']['output']>;
  trays2?: Maybe<Scalars['Float']['output']>;
  traysTray1?: Maybe<Scalars['Float']['output']>;
  traysTray2?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<TaskType>;
  unit?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<CropVariant>;
  variantId?: Maybe<Scalars['String']['output']>;
  withinRowSpacing?: Maybe<Scalars['Float']['output']>;
};

export type TaskConnection = {
  __typename?: 'TaskConnection';
  edges?: Maybe<Array<TaskEdge>>;
  pageInfo: PageInfo;
};

export type TaskCropVariant = {
  __typename?: 'TaskCropVariant';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TaskEdge = {
  __typename?: 'TaskEdge';
  node: Task;
};

export enum TaskStatus {
  Completed = 'completed',
  InProgress = 'in_progress',
  Planned = 'planned'
}

export enum TaskType {
  Harvesting = 'harvesting',
  SeedingInField = 'seeding_in_field',
  SeedingInTray_1 = 'seeding_in_tray_1',
  TransplantToField = 'transplant_to_field',
  TransplantToTray_2 = 'transplant_to_tray_2'
}

export type TasksDelete = {
  __typename?: 'TasksDelete';
  id: Scalars['ID']['output'];
};

export type Team = {
  __typename?: 'Team';
  avatar?: Maybe<Image>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Template = Node & {
  __typename?: 'Template';
  bedWidth?: Maybe<Scalars['Float']['output']>;
  cellsPerContainerTray1?: Maybe<Scalars['Int']['output']>;
  cellsPerContainerTray2?: Maybe<Scalars['Int']['output']>;
  coCrop?: Maybe<CoCropsType>;
  computed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crop?: Maybe<Crop>;
  id: Scalars['ID']['output'];
  locationType?: Maybe<TemplateLocationType>;
  method?: Maybe<TemplateMethod>;
  name: Scalars['String']['output'];
  plantings?: Maybe<Array<Maybe<Planting>>>;
  plantsPerAcre?: Maybe<Scalars['Float']['output']>;
  plantsPerSeed?: Maybe<Scalars['Float']['output']>;
  plastic?: Maybe<Scalars['Boolean']['output']>;
  rowsPerBed?: Maybe<Scalars['Int']['output']>;
  seedsPerCellTray1?: Maybe<Scalars['Int']['output']>;
  survivalRateTray2?: Maybe<Scalars['Int']['output']>;
  transplantMethod?: Maybe<TransplantMethods>;
  transplantMethodTray2?: Maybe<TransplantMethods>;
  transplantsPerCellTray2?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  withinRowSpacing?: Maybe<Scalars['Float']['output']>;
  yieldPerAcre?: Maybe<Scalars['Int']['output']>;
  yieldPerBedFoot?: Maybe<Scalars['Float']['output']>;
  yieldPerPlant?: Maybe<Scalars['Float']['output']>;
  yieldUnit?: Maybe<CropUnitTypes>;
};

export type TemplateConnection = {
  __typename?: 'TemplateConnection';
  edges: Array<Maybe<TemplateEdge>>;
  pageInfo: PageInfo;
};

export type TemplateCreateInput = {
  bedWidth?: InputMaybe<Scalars['Float']['input']>;
  cellsPerContainerTray1?: InputMaybe<Scalars['Int']['input']>;
  cellsPerContainerTray2?: InputMaybe<Scalars['Int']['input']>;
  coCrop?: InputMaybe<CoCropsType>;
  cropId?: InputMaybe<Scalars['ID']['input']>;
  locationType?: InputMaybe<TemplateLocationType>;
  method?: InputMaybe<TemplateMethod>;
  name: Scalars['String']['input'];
  plastic?: InputMaybe<Scalars['Boolean']['input']>;
  rowsPerBed?: InputMaybe<Scalars['Int']['input']>;
  seedsPerCellTray1?: InputMaybe<Scalars['Int']['input']>;
  survivalRateTray2?: InputMaybe<Scalars['Int']['input']>;
  transplantMethod?: InputMaybe<TransplantMethods>;
  transplantMethodTray2?: InputMaybe<TransplantMethods>;
  transplantsPerCellTray2?: InputMaybe<Scalars['Int']['input']>;
  withinRowSpacing?: InputMaybe<Scalars['Float']['input']>;
  yieldPerAcre?: InputMaybe<Scalars['Int']['input']>;
  yieldPerBedFoot?: InputMaybe<Scalars['Float']['input']>;
  yieldUnit?: InputMaybe<CropUnitTypes>;
};

export type TemplateDelete = {
  __typename?: 'TemplateDelete';
  id: Scalars['ID']['output'];
};

export type TemplateDeleteBatchInput = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type TemplateEdge = {
  __typename?: 'TemplateEdge';
  node?: Maybe<Template>;
};

export enum TemplateLocationType {
  Field = 'field',
  HighTunnel = 'high_tunnel'
}

export enum TemplateMethod {
  DirectSeed = 'direct_seed',
  Transplant = 'transplant'
}

export type TemplateUpdateInput = {
  bedWidth?: InputMaybe<Scalars['Float']['input']>;
  cellsPerContainerTray1?: InputMaybe<Scalars['Int']['input']>;
  cellsPerContainerTray2?: InputMaybe<Scalars['Int']['input']>;
  coCrop?: InputMaybe<CoCropsType>;
  cropId?: InputMaybe<Scalars['ID']['input']>;
  locationType?: InputMaybe<TemplateLocationType>;
  method?: InputMaybe<TemplateMethod>;
  name?: InputMaybe<Scalars['String']['input']>;
  plastic?: InputMaybe<Scalars['Boolean']['input']>;
  rowsPerBed?: InputMaybe<Scalars['Int']['input']>;
  seedsPerCellTray1?: InputMaybe<Scalars['Int']['input']>;
  survivalRateTray2?: InputMaybe<Scalars['Int']['input']>;
  templateId: Scalars['ID']['input'];
  transplantMethod?: InputMaybe<TransplantMethods>;
  transplantMethodTray2?: InputMaybe<TransplantMethods>;
  transplantsPerCellTray2?: InputMaybe<Scalars['Int']['input']>;
  withinRowSpacing?: InputMaybe<Scalars['Float']['input']>;
  yieldPerAcre?: InputMaybe<Scalars['Int']['input']>;
  yieldPerBedFoot?: InputMaybe<Scalars['Float']['input']>;
  yieldUnit?: InputMaybe<CropUnitTypes>;
};

export type TemplateWizardInput = {
  bedWidth?: InputMaybe<Scalars['Float']['input']>;
  cellsPerContainerTray1?: InputMaybe<Scalars['Float']['input']>;
  cellsPerContainerTray2?: InputMaybe<Scalars['Float']['input']>;
  coCrop?: InputMaybe<CoCropsType>;
  locationType?: InputMaybe<TemplateLocationType>;
  method?: InputMaybe<TemplateMethod>;
  name: Scalars['String']['input'];
  plastic?: InputMaybe<Scalars['Boolean']['input']>;
  rowsPerBed?: InputMaybe<Scalars['Float']['input']>;
  seedsPerCellTray1?: InputMaybe<Scalars['Float']['input']>;
  survivalRateTray2?: InputMaybe<Scalars['Float']['input']>;
  transplantMethod?: InputMaybe<TransplantMethods>;
  transplantMethodTray2?: InputMaybe<TransplantMethods>;
  transplantsPerCellTray2?: InputMaybe<Scalars['Float']['input']>;
  variantName?: InputMaybe<Scalars['String']['input']>;
  withinRowSpacing?: InputMaybe<Scalars['Float']['input']>;
  yieldPerAcre?: InputMaybe<Scalars['Float']['input']>;
  yieldPerBedFoot?: InputMaybe<Scalars['Float']['input']>;
  yieldUnit?: InputMaybe<CropUnitTypes>;
};

export type TemplateWizardResponse = {
  __typename?: 'TemplateWizardResponse';
  bedWidth?: Maybe<Scalars['Float']['output']>;
  cellsPerContainerTray1?: Maybe<Scalars['Float']['output']>;
  cellsPerContainerTray2?: Maybe<Scalars['Float']['output']>;
  coCrop?: Maybe<CoCropsType>;
  locationType?: Maybe<TemplateLocationType>;
  method?: Maybe<TemplateMethod>;
  name?: Maybe<Scalars['String']['output']>;
  plastic?: Maybe<Scalars['Boolean']['output']>;
  rowsPerBed?: Maybe<Scalars['Float']['output']>;
  seedsPerCellTray1?: Maybe<Scalars['Float']['output']>;
  survivalRateTray2?: Maybe<Scalars['Float']['output']>;
  transplantMethod?: Maybe<TransplantMethods>;
  transplantMethodTray2?: Maybe<TransplantMethods>;
  transplantsPerCellTray2?: Maybe<Scalars['Float']['output']>;
  withinRowSpacing?: Maybe<Scalars['Float']['output']>;
  yieldPerBedFoot?: Maybe<Scalars['Float']['output']>;
  yieldUnit?: Maybe<CropUnitTypes>;
};

export enum TransplantMethods {
  BareRoot = 'bare_root',
  Plugs = 'plugs'
}

export enum TriggerAction {
  Email = 'email'
}

export type Unit = {
  __typename?: 'Unit';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  subQuantity?: Maybe<Scalars['Int']['output']>;
  subUnit?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type UnitConnection = {
  __typename?: 'UnitConnection';
  edges?: Maybe<Array<UnitEdge>>;
  pageInfo: PageInfo;
};

export type UnitCreateInput = {
  name: Scalars['String']['input'];
};

export type UnitEdge = {
  __typename?: 'UnitEdge';
  node: Unit;
};

export type UpdateCartItemInput = {
  cartId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  storeId: Scalars['ID']['input'];
};

export type UpdateCheckoutBillingAddressInput = {
  billingAddressId: Scalars['String']['input'];
  checkoutId: Scalars['ID']['input'];
};

export type UpdateCheckoutPaymentMethodInput = {
  checkoutId: Scalars['ID']['input'];
  paymentMethodId: Scalars['String']['input'];
  paymentMethodType: PaymentMethodType;
};

export type UpdateCheckoutShippingAddressInput = {
  checkoutId: Scalars['ID']['input'];
  shippingAddressId: Scalars['String']['input'];
};

export type UpdateCheckoutShippingOptionInput = {
  checkoutId: Scalars['ID']['input'];
  date: Scalars['DateTime']['input'];
  locationId: Scalars['ID']['input'];
  type: ShippingOptionType;
};

export type UpdateFertilizerInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInventoryLocationInput = {
  coordinates?: InputMaybe<LocationCoordinatesInput>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InventoryLocationType>;
};

export type UpdatePesticideInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePesticideInventoryEntryInput = {
  container?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  pesticideId?: InputMaybe<Scalars['ID']['input']>;
  purchaseDate?: InputMaybe<Scalars['DateTime']['input']>;
  purchaseLotNumber?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<InventoryEntryUserInput>;
};

export type UpdateSeedInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  seedId: Scalars['ID']['input'];
  seedsPerUnit?: InputMaybe<Array<InputMaybe<SeedsPerUnitInput>>>;
};

export type UpdateTagInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaskInput = {
  assignedId?: InputMaybe<Scalars['String']['input']>;
  bedFeet?: InputMaybe<Scalars['Float']['input']>;
  bedWidth?: InputMaybe<Scalars['Float']['input']>;
  cellsPerContainerTray1?: InputMaybe<Scalars['Int']['input']>;
  cellsPerContainerTray2?: InputMaybe<Scalars['Int']['input']>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  cropId?: InputMaybe<Scalars['ID']['input']>;
  fieldPlantDate?: InputMaybe<Scalars['DateTime']['input']>;
  harvestDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  locationId?: InputMaybe<Scalars['String']['input']>;
  plantingId?: InputMaybe<Scalars['ID']['input']>;
  potUpDate?: InputMaybe<Scalars['DateTime']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  rowsPerBed?: InputMaybe<Scalars['Int']['input']>;
  seedInventoryEntryId?: InputMaybe<Scalars['ID']['input']>;
  seedingDate?: InputMaybe<Scalars['DateTime']['input']>;
  seedsPerCellTray1?: InputMaybe<Scalars['Int']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<TaskStatus>;
  transplantDate?: InputMaybe<Scalars['DateTime']['input']>;
  transplantsPerCellTray2?: InputMaybe<Scalars['Int']['input']>;
  trays?: InputMaybe<Scalars['Float']['input']>;
  trays2?: InputMaybe<Scalars['Float']['input']>;
  traysTray1?: InputMaybe<Scalars['Float']['input']>;
  traysTray2?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<TaskType>;
  unit?: InputMaybe<Scalars['String']['input']>;
  variantId?: InputMaybe<Scalars['String']['input']>;
  withinRowSpacing?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateTeamMemberInput = {
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserAddressInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type UpdateUserInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailAddress']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  accounts?: Maybe<Array<Maybe<Account>>>;
  billingAddresses: Array<UserAddress>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['EmailAddress']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  level?: Maybe<CustomerType>;
  paymentMethods: Array<Maybe<PaymentMethod>>;
  phone?: Maybe<Scalars['String']['output']>;
  shippingAddresses: Array<UserAddress>;
  team?: Maybe<Team>;
  type: UserType;
  username: Scalars['String']['output'];
};


export type UserPaymentMethodsArgs = {
  producerId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  province?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export enum UserType {
  Customer = 'CUSTOMER',
  Producer = 'PRODUCER'
}

export type Variant = {
  __typename?: 'Variant';
  baseTemperature?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  crop: VariantCrop;
  daysToMaturity?: Maybe<Scalars['Int']['output']>;
  growingDegreeDaysToMaturity?: Maybe<Scalars['Int']['output']>;
  harvestDuration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  name: Scalars['String']['output'];
  singleDayHarvest?: Maybe<Scalars['Boolean']['output']>;
  tray1Days?: Maybe<Scalars['Int']['output']>;
  tray1MaxDays?: Maybe<Scalars['Int']['output']>;
  tray2Days?: Maybe<Scalars['Int']['output']>;
  tray2MaxDays?: Maybe<Scalars['Int']['output']>;
};

export type VariantCrop = {
  __typename?: 'VariantCrop';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  subCategory?: Maybe<SubCategory>;
};

export type VariantPrice = {
  __typename?: 'VariantPrice';
  retail?: Maybe<Scalars['Int']['output']>;
  wholesale?: Maybe<Scalars['Int']['output']>;
};

export type WeatherForecast = {
  __typename?: 'WeatherForecast';
  date: Scalars['DateTime']['output'];
  dewpoint?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  maxTemperature: Scalars['Float']['output'];
  minTemperature: Scalars['Float']['output'];
  probabilityOfPrecipitation?: Maybe<Scalars['Int']['output']>;
  relativeHumidity?: Maybe<Scalars['Int']['output']>;
  shortForecast?: Maybe<ShortForecast>;
  site?: Maybe<Site>;
  source?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  windSpeed?: Maybe<Scalars['String']['output']>;
};

export type WeatherForecastConnection = {
  __typename?: 'WeatherForecastConnection';
  edges: Array<Maybe<WeatherForecastEdge>>;
  pageInfo: PageInfo;
};

export type WeatherForecastEdge = {
  __typename?: 'WeatherForecastEdge';
  node?: Maybe<WeatherForecast>;
};

export type WeatherForecastFilter = {
  siteId?: InputMaybe<Scalars['String']['input']>;
};

export type WeeklyHarvest = {
  __typename?: 'WeeklyHarvest';
  forecastedQuantity?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  scoutedQuantity?: Maybe<Scalars['Float']['output']>;
  weekStartDate?: Maybe<Scalars['DateTime']['output']>;
  weekYear?: Maybe<Scalars['String']['output']>;
};

export type WeeklyHarvestInput = {
  forecastedQuantity?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  scoutedQuantity?: InputMaybe<Scalars['Float']['input']>;
  weekStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  weekYear?: InputMaybe<Scalars['String']['input']>;
};

export type Worklog = {
  __typename?: 'Worklog';
  closed?: Maybe<Scalars['Boolean']['output']>;
  completedByUserId?: Maybe<Scalars['String']['output']>;
  completedByUsername?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  photos: Array<WorklogPhoto>;
  planting: Planting;
  plantingId: Scalars['String']['output'];
  previousLocationName?: Maybe<Scalars['String']['output']>;
  producer: Producer;
  producerId: Scalars['String']['output'];
  source?: Maybe<Source>;
  type: WorklogType;
  updatedAt: Scalars['DateTime']['output'];
  weeklyHarvest?: Maybe<Array<Maybe<WeeklyHarvest>>>;
};

export type WorklogConnection = {
  __typename?: 'WorklogConnection';
  edges: Array<WorklogEdge>;
  pageInfo: PageInfo;
};

export type WorklogEdge = {
  __typename?: 'WorklogEdge';
  node: Worklog;
};

export type WorklogFilterInput = {
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  plantingId?: InputMaybe<Scalars['String']['input']>;
  producerId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<WorklogType>;
};

export type WorklogInput = {
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  completedByUserId?: InputMaybe<Scalars['String']['input']>;
  completedByUsername?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  plantingId: Scalars['String']['input'];
  previousLocationName?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Source>;
  type: WorklogType;
  weeklyHarvest?: InputMaybe<Array<InputMaybe<WeeklyHarvestInput>>>;
};

export type WorklogPhoto = {
  __typename?: 'WorklogPhoto';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export enum WorklogType {
  Fertilization = 'fertilization',
  HarvestAvailability = 'harvest_availability',
  Irrigation = 'irrigation',
  Other = 'other',
  PestControl = 'pest_control'
}

export type WorklogUpdateInput = {
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  plantingId?: InputMaybe<Scalars['String']['input']>;
  previousLocationName?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Source>;
  type?: InputMaybe<WorklogType>;
  weeklyHarvest?: InputMaybe<Array<InputMaybe<WeeklyHarvestInput>>>;
};

export type CartFragmentFragment = { __typename?: 'Cart', id: string, totalUniqueItems: number, grandTotal: number, subTotal: number, note?: string | null, createdAt: any, updatedAt: any, minimumOrderAmount: number, items?: Array<{ __typename?: 'CartItem', id: string, image?: string | null, lineTotal: number, name?: string | null, variantName?: string | null, note?: string | null, quantity: number, unitQuantity?: number | null, unit?: string | null, unitTotal: number }> | null };

export type CartQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  storeId: Scalars['ID']['input'];
}>;


export type CartQuery = { __typename?: 'Query', cart: { __typename?: 'Cart', id: string, totalUniqueItems: number, grandTotal: number, subTotal: number, note?: string | null, createdAt: any, updatedAt: any, minimumOrderAmount: number, items?: Array<{ __typename?: 'CartItem', id: string, image?: string | null, lineTotal: number, name?: string | null, variantName?: string | null, note?: string | null, quantity: number, unitQuantity?: number | null, unit?: string | null, unitTotal: number }> | null } };

export type CartsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CartsQuery = { __typename?: 'Query', carts: { __typename?: 'CartsConnection', id: string, edges: Array<{ __typename?: 'CartEdge', node?: { __typename?: 'Cart', id: string, store: { __typename?: 'Store', id: string, slug: string, name: string, logo?: string | null }, items?: Array<{ __typename?: 'CartItem', id: string, image?: string | null }> | null } | null } | null> } };

export type AddCartItemMutationVariables = Exact<{
  input: AddToCartInput;
}>;


export type AddCartItemMutation = { __typename?: 'Mutation', addItem: { __typename?: 'Cart', id: string, totalUniqueItems: number, grandTotal: number, subTotal: number, note?: string | null, createdAt: any, updatedAt: any, minimumOrderAmount: number, items?: Array<{ __typename?: 'CartItem', id: string, image?: string | null, lineTotal: number, name?: string | null, variantName?: string | null, note?: string | null, quantity: number, unitQuantity?: number | null, unit?: string | null, unitTotal: number }> | null } };

export type RemoveCartItemMutationVariables = Exact<{
  input: RemoveCartItemInput;
}>;


export type RemoveCartItemMutation = { __typename?: 'Mutation', removeItem: { __typename?: 'Cart', id: string, totalUniqueItems: number, grandTotal: number, subTotal: number, note?: string | null, createdAt: any, updatedAt: any, minimumOrderAmount: number, items?: Array<{ __typename?: 'CartItem', id: string, image?: string | null, lineTotal: number, name?: string | null, variantName?: string | null, note?: string | null, quantity: number, unitQuantity?: number | null, unit?: string | null, unitTotal: number }> | null } };

export type UpdateCartItemMutationVariables = Exact<{
  input: UpdateCartItemInput;
}>;


export type UpdateCartItemMutation = { __typename?: 'Mutation', updateItem: { __typename?: 'Cart', id: string, totalUniqueItems: number, grandTotal: number, subTotal: number, note?: string | null, createdAt: any, updatedAt: any, minimumOrderAmount: number, items?: Array<{ __typename?: 'CartItem', id: string, image?: string | null, lineTotal: number, name?: string | null, variantName?: string | null, note?: string | null, quantity: number, unitQuantity?: number | null, unit?: string | null, unitTotal: number }> | null } };

export type SetCartItemsMutationVariables = Exact<{
  input: SetCartItemsInput;
}>;


export type SetCartItemsMutation = { __typename?: 'Mutation', setItems: { __typename?: 'Cart', id: string, totalUniqueItems: number, grandTotal: number, subTotal: number, note?: string | null, createdAt: any, updatedAt: any, minimumOrderAmount: number, items?: Array<{ __typename?: 'CartItem', id: string, image?: string | null, lineTotal: number, name?: string | null, variantName?: string | null, note?: string | null, quantity: number, unitQuantity?: number | null, unit?: string | null, unitTotal: number }> | null } };

export type CheckoutFragmentFragment = { __typename?: 'CheckoutOrder', id: string, grandTotal: number, shippingTotal: number, subTotal: number, discountTotal: number, serviceFee: number, producer: { __typename?: 'Producer', id: string }, shipping?: { __typename?: 'CheckoutOrderShipping', date?: any | null, type?: OrderShippingType | null, location?: { __typename?: 'ProducerLocation', id: string } | null } | null, payment?: { __typename?: 'Payment', method?: { __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } | null } | null, shippingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, billingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, lineItems: Array<{ __typename?: 'OrderLineItem', id: string, name: string, unit: string, quantity: any, unitQuantity: any, price: number, note?: string | null, discountTotal: number, image?: { __typename?: 'Image', src?: string | null } | null } | null> };

export type CheckoutQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type CheckoutQuery = { __typename?: 'Query', checkout: { __typename?: 'CheckoutOrder', id: string, grandTotal: number, shippingTotal: number, subTotal: number, discountTotal: number, serviceFee: number, producer: { __typename?: 'Producer', id: string }, shipping?: { __typename?: 'CheckoutOrderShipping', date?: any | null, type?: OrderShippingType | null, location?: { __typename?: 'ProducerLocation', id: string } | null } | null, payment?: { __typename?: 'Payment', method?: { __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } | null } | null, shippingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, billingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, lineItems: Array<{ __typename?: 'OrderLineItem', id: string, name: string, unit: string, quantity: any, unitQuantity: any, price: number, note?: string | null, discountTotal: number, image?: { __typename?: 'Image', src?: string | null } | null } | null> } };

export type CheckoutPaymentMethodsQueryVariables = Exact<{
  producerId: Scalars['ID']['input'];
}>;


export type CheckoutPaymentMethodsQuery = { __typename?: 'Query', checkoutPaymentMethods: Array<{ __typename?: 'CheckoutPaymentMethod', id: string, name: string, brand?: string | null, type?: PaymentMethodType | null }> };

export type CheckoutUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckoutUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, phone?: string | null, paymentMethods: Array<{ __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } | null>, shippingAddresses: Array<{ __typename?: 'UserAddress', id: string, address1: string, city: string, isDefault: boolean, province?: string | null, zip: string }>, billingAddresses: Array<{ __typename?: 'UserAddress', id: string, address1: string, city: string, isDefault: boolean, province?: string | null, zip: string }> } | null };

export type CheckoutCreateMutationVariables = Exact<{
  input: CreateCheckoutInput;
}>;


export type CheckoutCreateMutation = { __typename?: 'Mutation', checkoutCreate: { __typename?: 'CheckoutOrder', id: string, grandTotal: number, shippingTotal: number, subTotal: number, discountTotal: number, serviceFee: number, producer: { __typename?: 'Producer', id: string }, shipping?: { __typename?: 'CheckoutOrderShipping', date?: any | null, type?: OrderShippingType | null, location?: { __typename?: 'ProducerLocation', id: string } | null } | null, payment?: { __typename?: 'Payment', method?: { __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } | null } | null, shippingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, billingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, lineItems: Array<{ __typename?: 'OrderLineItem', id: string, name: string, unit: string, quantity: any, unitQuantity: any, price: number, note?: string | null, discountTotal: number, image?: { __typename?: 'Image', src?: string | null } | null } | null> } };

export type UpdateCheckoutShippingOptionMutationVariables = Exact<{
  input: UpdateCheckoutShippingOptionInput;
}>;


export type UpdateCheckoutShippingOptionMutation = { __typename?: 'Mutation', updateCheckoutShippingOption: { __typename?: 'CheckoutOrder', id: string, grandTotal: number, shippingTotal: number, subTotal: number, discountTotal: number, serviceFee: number, producer: { __typename?: 'Producer', id: string }, shipping?: { __typename?: 'CheckoutOrderShipping', date?: any | null, type?: OrderShippingType | null, location?: { __typename?: 'ProducerLocation', id: string } | null } | null, payment?: { __typename?: 'Payment', method?: { __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } | null } | null, shippingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, billingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, lineItems: Array<{ __typename?: 'OrderLineItem', id: string, name: string, unit: string, quantity: any, unitQuantity: any, price: number, note?: string | null, discountTotal: number, image?: { __typename?: 'Image', src?: string | null } | null } | null> } };

export type UpdateCheckoutPaymentMethodMutationVariables = Exact<{
  input: UpdateCheckoutPaymentMethodInput;
}>;


export type UpdateCheckoutPaymentMethodMutation = { __typename?: 'Mutation', updateCheckoutPaymentMethod: { __typename?: 'CheckoutOrder', id: string, grandTotal: number, shippingTotal: number, subTotal: number, discountTotal: number, serviceFee: number, producer: { __typename?: 'Producer', id: string }, shipping?: { __typename?: 'CheckoutOrderShipping', date?: any | null, type?: OrderShippingType | null, location?: { __typename?: 'ProducerLocation', id: string } | null } | null, payment?: { __typename?: 'Payment', method?: { __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } | null } | null, shippingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, billingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, lineItems: Array<{ __typename?: 'OrderLineItem', id: string, name: string, unit: string, quantity: any, unitQuantity: any, price: number, note?: string | null, discountTotal: number, image?: { __typename?: 'Image', src?: string | null } | null } | null> } };

export type CompleteCheckoutMutationVariables = Exact<{
  input: CompleteCheckoutInput;
}>;


export type CompleteCheckoutMutation = { __typename?: 'Mutation', completeCheckout: { __typename?: 'CheckoutOrder', id: string, grandTotal: number, shippingTotal: number, subTotal: number, discountTotal: number, serviceFee: number, producer: { __typename?: 'Producer', id: string }, shipping?: { __typename?: 'CheckoutOrderShipping', date?: any | null, type?: OrderShippingType | null, location?: { __typename?: 'ProducerLocation', id: string } | null } | null, payment?: { __typename?: 'Payment', method?: { __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } | null } | null, shippingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, billingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, lineItems: Array<{ __typename?: 'OrderLineItem', id: string, name: string, unit: string, quantity: any, unitQuantity: any, price: number, note?: string | null, discountTotal: number, image?: { __typename?: 'Image', src?: string | null } | null } | null> } };

export type CreateCheckoutPaymentMethodMutationVariables = Exact<{
  input: CreateCheckoutPaymentMethodInput;
}>;


export type CreateCheckoutPaymentMethodMutation = { __typename?: 'Mutation', createCheckoutPaymentMethod: { __typename?: 'CheckoutOrder', id: string, grandTotal: number, shippingTotal: number, subTotal: number, discountTotal: number, serviceFee: number, producer: { __typename?: 'Producer', id: string }, shipping?: { __typename?: 'CheckoutOrderShipping', date?: any | null, type?: OrderShippingType | null, location?: { __typename?: 'ProducerLocation', id: string } | null } | null, payment?: { __typename?: 'Payment', method?: { __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } | null } | null, shippingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, billingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, lineItems: Array<{ __typename?: 'OrderLineItem', id: string, name: string, unit: string, quantity: any, unitQuantity: any, price: number, note?: string | null, discountTotal: number, image?: { __typename?: 'Image', src?: string | null } | null } | null> } };

export type UpdateCheckoutShippingAddressMutationVariables = Exact<{
  input: UpdateCheckoutShippingAddressInput;
}>;


export type UpdateCheckoutShippingAddressMutation = { __typename?: 'Mutation', updateCheckoutShippingAddress: { __typename?: 'CheckoutOrder', id: string, grandTotal: number, shippingTotal: number, subTotal: number, discountTotal: number, serviceFee: number, producer: { __typename?: 'Producer', id: string }, shipping?: { __typename?: 'CheckoutOrderShipping', date?: any | null, type?: OrderShippingType | null, location?: { __typename?: 'ProducerLocation', id: string } | null } | null, payment?: { __typename?: 'Payment', method?: { __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } | null } | null, shippingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, billingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, lineItems: Array<{ __typename?: 'OrderLineItem', id: string, name: string, unit: string, quantity: any, unitQuantity: any, price: number, note?: string | null, discountTotal: number, image?: { __typename?: 'Image', src?: string | null } | null } | null> } };

export type UpdateCheckoutBillingAddressMutationVariables = Exact<{
  input: UpdateCheckoutBillingAddressInput;
}>;


export type UpdateCheckoutBillingAddressMutation = { __typename?: 'Mutation', updateCheckoutBillingAddress: { __typename?: 'CheckoutOrder', id: string, grandTotal: number, shippingTotal: number, subTotal: number, discountTotal: number, serviceFee: number, producer: { __typename?: 'Producer', id: string }, shipping?: { __typename?: 'CheckoutOrderShipping', date?: any | null, type?: OrderShippingType | null, location?: { __typename?: 'ProducerLocation', id: string } | null } | null, payment?: { __typename?: 'Payment', method?: { __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } | null } | null, shippingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, billingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, lineItems: Array<{ __typename?: 'OrderLineItem', id: string, name: string, unit: string, quantity: any, unitQuantity: any, price: number, note?: string | null, discountTotal: number, image?: { __typename?: 'Image', src?: string | null } | null } | null> } };

export type AddCheckoutDiscountMutationVariables = Exact<{
  input: AddCheckoutDiscountInput;
}>;


export type AddCheckoutDiscountMutation = { __typename?: 'Mutation', addCheckoutDiscount: { __typename?: 'CheckoutOrder', id: string, grandTotal: number, shippingTotal: number, subTotal: number, discountTotal: number, serviceFee: number, producer: { __typename?: 'Producer', id: string }, shipping?: { __typename?: 'CheckoutOrderShipping', date?: any | null, type?: OrderShippingType | null, location?: { __typename?: 'ProducerLocation', id: string } | null } | null, payment?: { __typename?: 'Payment', method?: { __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } | null } | null, shippingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, billingAddress?: { __typename?: 'CheckoutAddress', id: string, address1: string, city: string, province?: string | null, zip: string } | null, lineItems: Array<{ __typename?: 'OrderLineItem', id: string, name: string, unit: string, quantity: any, unitQuantity: any, price: number, note?: string | null, discountTotal: number, image?: { __typename?: 'Image', src?: string | null } | null } | null> } };

export type CustomerFragmentFragment = { __typename?: 'Customer', id: string, type: CustomerType, firstName?: string | null, lastName?: string | null, companyName?: string | null };

export type VerifyInviteCodeQueryVariables = Exact<{
  inviteCode: Scalars['String']['input'];
}>;


export type VerifyInviteCodeQuery = { __typename?: 'Query', verifyInviteCode: { __typename?: 'Customer', id: string, type: CustomerType, firstName?: string | null, lastName?: string | null, companyName?: string | null } };

export type CustomerAccountCreateMutationVariables = Exact<{
  input: CustomerAccountCreateInput;
}>;


export type CustomerAccountCreateMutation = { __typename?: 'Mutation', customerAccountCreate: { __typename?: 'Customer', id: string, type: CustomerType, firstName?: string | null, lastName?: string | null, companyName?: string | null } };

export type OrderFragmentFragment = { __typename?: 'Order', id: string, hash: string, status: OrderStatus, name: string, note?: string | null, taxTotal: number, grandTotal: number, discountTotal: number, refundedTotal: number, invoiceUrl?: string | null, subTotal: number, shippingTotal: number, serviceFee: number, financialStatus: FinancialStatus, booked?: boolean | null, producer: { __typename?: 'Producer', id: string, slug: string, name: string, image?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null, logo?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null }, payment: { __typename?: 'Payment', completedAt?: any | null, initiatedAt?: any | null, method?: { __typename?: 'PaymentMethod', id?: string | null, name?: string | null, brand?: string | null, type?: PaymentMethodType | null } | null }, shipping: { __typename?: 'OrderShipping', type: OrderShippingType, date?: any | null, location?: { __typename?: 'ProducerLocation', name: string, latitude?: any | null, longitude?: any | null, address1: string, city?: string | null, province?: string | null, zip?: any | null, image?: { __typename?: 'Image', src?: string | null } | null } | null }, shippingAddress?: { __typename?: 'OrderShippingAddress', address1: string, city: string, zip: string, longitude?: any | null, latitude?: any | null, province?: string | null } | null, lineItems: Array<{ __typename?: 'OrderLineItem', id: string, name: string, unit: string, quantity: any, unitQuantity: any, price: number, note?: string | null, discountTotal: number, image?: { __typename?: 'Image', src?: string | null } | null } | null> };

export type OrdersQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: { __typename?: 'OrderConnection', edges: Array<{ __typename?: 'OrderEdge', node: { __typename?: 'Order', id: string, name: string, status: OrderStatus, financialStatus: FinancialStatus, grandTotal: number, producer: { __typename?: 'Producer', name: string, logo?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null }, shipping: { __typename?: 'OrderShipping', type: OrderShippingType, date?: any | null } } } | null> } };

export type OrderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OrderQuery = { __typename?: 'Query', order: { __typename?: 'Order', id: string, hash: string, status: OrderStatus, name: string, note?: string | null, taxTotal: number, grandTotal: number, discountTotal: number, refundedTotal: number, invoiceUrl?: string | null, subTotal: number, shippingTotal: number, serviceFee: number, financialStatus: FinancialStatus, booked?: boolean | null, producer: { __typename?: 'Producer', id: string, slug: string, name: string, image?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null, logo?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null }, payment: { __typename?: 'Payment', completedAt?: any | null, initiatedAt?: any | null, method?: { __typename?: 'PaymentMethod', id?: string | null, name?: string | null, brand?: string | null, type?: PaymentMethodType | null } | null }, shipping: { __typename?: 'OrderShipping', type: OrderShippingType, date?: any | null, location?: { __typename?: 'ProducerLocation', name: string, latitude?: any | null, longitude?: any | null, address1: string, city?: string | null, province?: string | null, zip?: any | null, image?: { __typename?: 'Image', src?: string | null } | null } | null }, shippingAddress?: { __typename?: 'OrderShippingAddress', address1: string, city: string, zip: string, longitude?: any | null, latitude?: any | null, province?: string | null } | null, lineItems: Array<{ __typename?: 'OrderLineItem', id: string, name: string, unit: string, quantity: any, unitQuantity: any, price: number, note?: string | null, discountTotal: number, image?: { __typename?: 'Image', src?: string | null } | null } | null> } };

export type PayOrderMutationVariables = Exact<{
  input: PayOrderInput;
}>;


export type PayOrderMutation = { __typename?: 'Mutation', payOrder: { __typename?: 'Order', id: string, hash: string, status: OrderStatus, name: string, note?: string | null, taxTotal: number, grandTotal: number, discountTotal: number, refundedTotal: number, invoiceUrl?: string | null, subTotal: number, shippingTotal: number, serviceFee: number, financialStatus: FinancialStatus, booked?: boolean | null, producer: { __typename?: 'Producer', id: string, slug: string, name: string, image?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null, logo?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null }, payment: { __typename?: 'Payment', completedAt?: any | null, initiatedAt?: any | null, method?: { __typename?: 'PaymentMethod', id?: string | null, name?: string | null, brand?: string | null, type?: PaymentMethodType | null } | null }, shipping: { __typename?: 'OrderShipping', type: OrderShippingType, date?: any | null, location?: { __typename?: 'ProducerLocation', name: string, latitude?: any | null, longitude?: any | null, address1: string, city?: string | null, province?: string | null, zip?: any | null, image?: { __typename?: 'Image', src?: string | null } | null } | null }, shippingAddress?: { __typename?: 'OrderShippingAddress', address1: string, city: string, zip: string, longitude?: any | null, latitude?: any | null, province?: string | null } | null, lineItems: Array<{ __typename?: 'OrderLineItem', id: string, name: string, unit: string, quantity: any, unitQuantity: any, price: number, note?: string | null, discountTotal: number, image?: { __typename?: 'Image', src?: string | null } | null } | null> } };

export type OrderItemsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OrderItemsQuery = { __typename?: 'Query', order: { __typename?: 'Order', id: string, name: string, producer: { __typename?: 'Producer', slug: string } }, orderItems: Array<{ __typename?: 'ReOrderLineItem', id: string, name: string, available: boolean, category: string, subCategory: string, quantity: any, unitQuantity: any, unitPrice: number, variant: { __typename?: 'ReOrderLineItemVariant', name: string, unit: string, type: ProductVariantType }, image: { __typename?: 'OrderLineItemImage', src: string, base64?: string | null, blurhash?: string | null, alt: string } } | null> };

export type PaymentMethodFragmentFragment = { __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null };

export type CreatePlaidPaymentMethodMutationVariables = Exact<{
  input: CreatePlaidPaymentMethodInput;
}>;


export type CreatePlaidPaymentMethodMutation = { __typename?: 'Mutation', createPlaidPaymentMethod: { __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } };

export type CreateCardPaymentMethodMutationVariables = Exact<{
  input: CreateCardPaymentMethodInput;
}>;


export type CreateCardPaymentMethodMutation = { __typename?: 'Mutation', createCardPaymentMethod: { __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } };

export type UpdateDefaultCardPaymentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UpdateDefaultCardPaymentMutation = { __typename?: 'Mutation', updateDefaultCardPayment: { __typename?: 'PaymentMethod', id?: string | null } };

export type DeleteCardPaymentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCardPaymentMutation = { __typename?: 'Mutation', deleteCardPaymentMethod: { __typename?: 'PaymentMethod', id?: string | null } };

export type ProducerIdsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ProducerIdsQuery = { __typename?: 'Query', producers: { __typename?: 'ProducerConnection', edges: Array<{ __typename?: 'ProducerEdge', node?: { __typename?: 'Producer', slug: string } | null } | null> } };

export type ProducerQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
  level?: InputMaybe<PriceLevel>;
}>;


export type ProducerQuery = { __typename?: 'Query', producer: { __typename?: 'Producer', id: string, name: string, slug: string, city?: string | null, province?: string | null, description?: { __typename?: 'ProducerDescription', retail?: string | null, wholesale?: string | null } | null, image?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null, logo?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null, products: { __typename?: 'ProductConnection', edges: Array<{ __typename?: 'ProductEdge', node?: { __typename?: 'Product', id: string, name: string, category?: { __typename?: 'Category', id: string, name: string } | null, subCategory?: { __typename?: 'SubCategory', id: string, name: string } | null, variants?: Array<{ __typename?: 'ProductVariant', id: string, name?: string | null, type: ProductVariantType, active: { __typename?: 'ProductVariantActive', wholesale?: boolean | null, retail?: boolean | null }, price?: { __typename?: 'VariantPrice', wholesale?: number | null, retail?: number | null } | null, unit?: { __typename?: 'ProductUnit', name?: string | null, quantity: number } | null }> | null, image?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null } | null } | null> } } };

export type ProductNodeFragment = { __typename?: 'Product', id: string, name: string, category?: { __typename?: 'Category', id: string, name: string } | null, subCategory?: { __typename?: 'SubCategory', id: string, name: string } | null, variants?: Array<{ __typename?: 'ProductVariant', id: string, name?: string | null, type: ProductVariantType, active: { __typename?: 'ProductVariantActive', wholesale?: boolean | null, retail?: boolean | null }, price?: { __typename?: 'VariantPrice', wholesale?: number | null, retail?: number | null } | null, unit?: { __typename?: 'ProductUnit', name?: string | null, quantity: number } | null }> | null, image?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null };

export type PreRenderProductsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  level?: InputMaybe<PriceLevel>;
}>;


export type PreRenderProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductConnection', edges: Array<{ __typename?: 'ProductEdge', node?: { __typename?: 'Product', id: string, producer?: { __typename?: 'Producer', slug: string } | null } | null } | null> } };

export type ProductQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  level?: InputMaybe<PriceLevel>;
}>;


export type ProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', description?: string | null, id: string, name: string, producer?: { __typename?: 'Producer', name: string, city?: string | null, province?: string | null, slug: string, description?: { __typename?: 'ProducerDescription', retail?: string | null, wholesale?: string | null } | null, image?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null, logo?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null } | null, category?: { __typename?: 'Category', id: string, name: string } | null, subCategory?: { __typename?: 'SubCategory', id: string, name: string } | null, variants?: Array<{ __typename?: 'ProductVariant', id: string, name?: string | null, type: ProductVariantType, active: { __typename?: 'ProductVariantActive', wholesale?: boolean | null, retail?: boolean | null }, price?: { __typename?: 'VariantPrice', wholesale?: number | null, retail?: number | null } | null, unit?: { __typename?: 'ProductUnit', name?: string | null, quantity: number } | null }> | null, image?: { __typename?: 'Image', src?: string | null, base64?: string | null } | null } };

export type ShippingQueryVariables = Exact<{
  producerId: Scalars['ID']['input'];
}>;


export type ShippingQuery = { __typename?: 'Query', shipping: Array<{ __typename?: 'ShippingConnection', type?: string | null, edges?: Array<{ __typename?: 'ShippingEdge', node: { __typename?: 'Shipping', next: any, until: any, location: { __typename?: 'ProducerLocation', id: string, name: string, address1: string, city?: string | null, province?: string | null, zip?: any | null, pickupStart?: number | null, pickupEnd?: number | null, image?: { __typename?: 'Image', src?: string | null } | null } } } | null> | null } | null> };

export type UserAddressFragmentFragment = { __typename?: 'UserAddress', id: string, address1: string, address2?: string | null, city: string, isDefault: boolean, province?: string | null, zip: string };

export type UserFragmentFragment = { __typename?: 'User', id: string, firstName?: string | null, username: string, lastName?: string | null, createdAt: any, phone?: string | null, email: any, team?: { __typename?: 'Team', name?: string | null } | null };

export type UserTeamMembersFragmentFragment = { __typename?: 'User', id: string, accounts?: Array<{ __typename?: 'Account', id: string, email: any, firstName?: string | null, lastName?: string | null, createdAt: any, lastActive?: any | null } | null> | null };

export type AccountUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, firstName?: string | null, username: string, lastName?: string | null, createdAt: any, phone?: string | null, email: any, shippingAddresses: Array<{ __typename?: 'UserAddress', id: string, address1: string, address2?: string | null, city: string, isDefault: boolean, province?: string | null, zip: string }>, billingAddresses: Array<{ __typename?: 'UserAddress', id: string, address1: string, address2?: string | null, city: string, isDefault: boolean, province?: string | null, zip: string }>, team?: { __typename?: 'Team', name?: string | null } | null } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email: any, team?: { __typename?: 'Team', name?: string | null } | null } | null };

export type UserTeamMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type UserTeamMembersQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, accounts?: Array<{ __typename?: 'Account', id: string, email: any, firstName?: string | null, lastName?: string | null, createdAt: any, lastActive?: any | null } | null> | null } | null };

export type UserPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserPaymentMethodsQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, paymentMethods: Array<{ __typename?: 'PaymentMethod', id?: string | null, brand?: string | null, isDefault?: boolean | null, name?: string | null, description?: string | null, type?: PaymentMethodType | null } | null> } | null };

export type UserAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserAddressesQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, shippingAddresses: Array<{ __typename?: 'UserAddress', id: string, address1: string, address2?: string | null, city: string, isDefault: boolean, province?: string | null, zip: string }>, billingAddresses: Array<{ __typename?: 'UserAddress', id: string, address1: string, address2?: string | null, city: string, isDefault: boolean, province?: string | null, zip: string }> } | null };

export type CreateUserAddressMutationVariables = Exact<{
  input: CreateUserAddressInput;
}>;


export type CreateUserAddressMutation = { __typename?: 'Mutation', createUserAddress?: { __typename?: 'User', id: string, shippingAddresses: Array<{ __typename?: 'UserAddress', id: string, address1: string, address2?: string | null, city: string, isDefault: boolean, province?: string | null, zip: string }>, billingAddresses: Array<{ __typename?: 'UserAddress', id: string, address1: string, address2?: string | null, city: string, isDefault: boolean, province?: string | null, zip: string }> } | null };

export type DeleteUserAddressMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteUserAddressMutation = { __typename?: 'Mutation', deleteUserAddress: { __typename?: 'User', id: string, shippingAddresses: Array<{ __typename?: 'UserAddress', id: string, address1: string, address2?: string | null, city: string, isDefault: boolean, province?: string | null, zip: string }>, billingAddresses: Array<{ __typename?: 'UserAddress', id: string, address1: string, address2?: string | null, city: string, isDefault: boolean, province?: string | null, zip: string }> } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', email: any } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, firstName?: string | null, username: string, lastName?: string | null, createdAt: any, phone?: string | null, email: any, team?: { __typename?: 'Team', name?: string | null } | null } };

export type UpdateUserAddressMutationVariables = Exact<{
  input: UpdateUserAddressInput;
}>;


export type UpdateUserAddressMutation = { __typename?: 'Mutation', updateUserAddress?: { __typename?: 'User', id: string, shippingAddresses: Array<{ __typename?: 'UserAddress', id: string, address1: string, address2?: string | null, city: string, isDefault: boolean, province?: string | null, zip: string }>, billingAddresses: Array<{ __typename?: 'UserAddress', id: string, address1: string, address2?: string | null, city: string, isDefault: boolean, province?: string | null, zip: string }> } | null };

export type CreateTeamMemberMutationVariables = Exact<{
  input: CreateTeamMemberInput;
}>;


export type CreateTeamMemberMutation = { __typename?: 'Mutation', createTeamMember: { __typename?: 'User', id: string, accounts?: Array<{ __typename?: 'Account', id: string, email: any, firstName?: string | null, lastName?: string | null, createdAt: any, lastActive?: any | null } | null> | null } };

export type UpdateTeamMemberMutationVariables = Exact<{
  input: UpdateTeamMemberInput;
}>;


export type UpdateTeamMemberMutation = { __typename?: 'Mutation', updateTeamMember: { __typename?: 'User', id: string, accounts?: Array<{ __typename?: 'Account', id: string, email: any, firstName?: string | null, lastName?: string | null, createdAt: any, lastActive?: any | null } | null> | null } };

export type DeleteTeamMemberMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteTeamMemberMutation = { __typename?: 'Mutation', deleteTeamMember: { __typename?: 'User', id: string, accounts?: Array<{ __typename?: 'Account', id: string, email: any, firstName?: string | null, lastName?: string | null, createdAt: any, lastActive?: any | null } | null> | null } };

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'AccessTokens', accessToken?: any | null, refreshToken?: any | null } };

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'ForgotPassword', success?: boolean | null } };

export const CartFragmentFragmentDoc = gql`
    fragment CartFragment on Cart {
  id
  totalUniqueItems
  grandTotal
  subTotal
  items {
    id
    image
    lineTotal
    name
    variantName
    note
    quantity
    unitQuantity
    unit
    unitTotal
  }
  note
  createdAt
  updatedAt
  minimumOrderAmount
}
    `;
export const PaymentMethodFragmentFragmentDoc = gql`
    fragment PaymentMethodFragment on PaymentMethod {
  id
  brand
  isDefault
  name
  description
  type
}
    `;
export const CheckoutFragmentFragmentDoc = gql`
    fragment CheckoutFragment on CheckoutOrder {
  id
  grandTotal
  shippingTotal
  subTotal
  discountTotal
  serviceFee
  producer {
    id
  }
  shipping {
    date
    type
    location {
      id
    }
  }
  payment {
    method {
      ...PaymentMethodFragment
    }
  }
  shippingAddress {
    id
    address1
    city
    province
    zip
  }
  billingAddress {
    id
    address1
    city
    province
    zip
  }
  lineItems {
    id
    name
    unit
    quantity
    unitQuantity
    price
    note
    discountTotal
    image {
      src
    }
  }
}
    ${PaymentMethodFragmentFragmentDoc}`;
export const CustomerFragmentFragmentDoc = gql`
    fragment CustomerFragment on Customer {
  id
  type
  firstName
  lastName
  companyName
}
    `;
export const OrderFragmentFragmentDoc = gql`
    fragment OrderFragment on Order {
  id
  hash
  status
  name
  note
  taxTotal
  grandTotal
  discountTotal
  refundedTotal
  invoiceUrl
  subTotal
  shippingTotal
  serviceFee
  financialStatus
  booked
  producer {
    id
    slug
    name
    image {
      src
      base64
    }
    logo {
      src
      base64
    }
  }
  payment {
    completedAt
    initiatedAt
    method {
      id
      name
      brand
      type
    }
  }
  shipping {
    type
    date
    location {
      name
      latitude
      longitude
      address1
      city
      province
      zip
      image {
        src
      }
    }
  }
  shippingAddress {
    address1
    city
    zip
    longitude
    latitude
    province
  }
  lineItems {
    id
    name
    unit
    quantity
    unitQuantity
    price
    note
    discountTotal
    image {
      src
    }
  }
}
    `;
export const ProductNodeFragmentDoc = gql`
    fragment ProductNode on Product {
  id
  name
  category {
    id
    name
  }
  subCategory {
    id
    name
  }
  variants {
    id
    active {
      wholesale
      retail
    }
    price {
      wholesale
      retail
    }
    unit {
      name
      quantity
    }
    name
    type
  }
  image {
    src
    base64
  }
}
    `;
export const UserAddressFragmentFragmentDoc = gql`
    fragment UserAddressFragment on UserAddress {
  id
  address1
  address2
  city
  isDefault
  province
  zip
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  firstName
  username
  lastName
  createdAt
  phone
  email
  team {
    name
  }
}
    `;
export const UserTeamMembersFragmentFragmentDoc = gql`
    fragment UserTeamMembersFragment on User {
  id
  accounts {
    id
    email
    firstName
    lastName
    createdAt
    lastActive
  }
}
    `;
export const CartDocument = gql`
    query Cart($id: ID!, $storeId: ID!) {
  cart(id: $id, storeId: $storeId) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *      id: // value for 'id'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useCartQuery(baseOptions: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options);
      }
export function useCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(CartDocument, options);
        }
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const CartsDocument = gql`
    query Carts($id: ID!) {
  carts(id: $id) {
    id
    edges {
      node {
        id
        store {
          id
          slug
          name
          logo
        }
        items {
          id
          image
        }
      }
    }
  }
}
    `;

/**
 * __useCartsQuery__
 *
 * To run a query within a React component, call `useCartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCartsQuery(baseOptions: Apollo.QueryHookOptions<CartsQuery, CartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CartsQuery, CartsQueryVariables>(CartsDocument, options);
      }
export function useCartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CartsQuery, CartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CartsQuery, CartsQueryVariables>(CartsDocument, options);
        }
export type CartsQueryHookResult = ReturnType<typeof useCartsQuery>;
export type CartsLazyQueryHookResult = ReturnType<typeof useCartsLazyQuery>;
export type CartsQueryResult = Apollo.QueryResult<CartsQuery, CartsQueryVariables>;
export const AddCartItemDocument = gql`
    mutation AddCartItem($input: AddToCartInput!) {
  addItem(input: $input) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;
export type AddCartItemMutationFn = Apollo.MutationFunction<AddCartItemMutation, AddCartItemMutationVariables>;

/**
 * __useAddCartItemMutation__
 *
 * To run a mutation, you first call `useAddCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCartItemMutation, { data, loading, error }] = useAddCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCartItemMutation(baseOptions?: Apollo.MutationHookOptions<AddCartItemMutation, AddCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCartItemMutation, AddCartItemMutationVariables>(AddCartItemDocument, options);
      }
export type AddCartItemMutationHookResult = ReturnType<typeof useAddCartItemMutation>;
export type AddCartItemMutationResult = Apollo.MutationResult<AddCartItemMutation>;
export type AddCartItemMutationOptions = Apollo.BaseMutationOptions<AddCartItemMutation, AddCartItemMutationVariables>;
export const RemoveCartItemDocument = gql`
    mutation RemoveCartItem($input: RemoveCartItemInput!) {
  removeItem(input: $input) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;
export type RemoveCartItemMutationFn = Apollo.MutationFunction<RemoveCartItemMutation, RemoveCartItemMutationVariables>;

/**
 * __useRemoveCartItemMutation__
 *
 * To run a mutation, you first call `useRemoveCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCartItemMutation, { data, loading, error }] = useRemoveCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCartItemMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCartItemMutation, RemoveCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCartItemMutation, RemoveCartItemMutationVariables>(RemoveCartItemDocument, options);
      }
export type RemoveCartItemMutationHookResult = ReturnType<typeof useRemoveCartItemMutation>;
export type RemoveCartItemMutationResult = Apollo.MutationResult<RemoveCartItemMutation>;
export type RemoveCartItemMutationOptions = Apollo.BaseMutationOptions<RemoveCartItemMutation, RemoveCartItemMutationVariables>;
export const UpdateCartItemDocument = gql`
    mutation UpdateCartItem($input: UpdateCartItemInput!) {
  updateItem(input: $input) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;
export type UpdateCartItemMutationFn = Apollo.MutationFunction<UpdateCartItemMutation, UpdateCartItemMutationVariables>;

/**
 * __useUpdateCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemMutation, { data, loading, error }] = useUpdateCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCartItemMutation, UpdateCartItemMutationVariables>(UpdateCartItemDocument, options);
      }
export type UpdateCartItemMutationHookResult = ReturnType<typeof useUpdateCartItemMutation>;
export type UpdateCartItemMutationResult = Apollo.MutationResult<UpdateCartItemMutation>;
export type UpdateCartItemMutationOptions = Apollo.BaseMutationOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>;
export const SetCartItemsDocument = gql`
    mutation SetCartItems($input: SetCartItemsInput!) {
  setItems(input: $input) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;
export type SetCartItemsMutationFn = Apollo.MutationFunction<SetCartItemsMutation, SetCartItemsMutationVariables>;

/**
 * __useSetCartItemsMutation__
 *
 * To run a mutation, you first call `useSetCartItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCartItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCartItemsMutation, { data, loading, error }] = useSetCartItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCartItemsMutation(baseOptions?: Apollo.MutationHookOptions<SetCartItemsMutation, SetCartItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCartItemsMutation, SetCartItemsMutationVariables>(SetCartItemsDocument, options);
      }
export type SetCartItemsMutationHookResult = ReturnType<typeof useSetCartItemsMutation>;
export type SetCartItemsMutationResult = Apollo.MutationResult<SetCartItemsMutation>;
export type SetCartItemsMutationOptions = Apollo.BaseMutationOptions<SetCartItemsMutation, SetCartItemsMutationVariables>;
export const CheckoutDocument = gql`
    query Checkout($id: String!) {
  checkout(id: $id) {
    ...CheckoutFragment
  }
}
    ${CheckoutFragmentFragmentDoc}`;

/**
 * __useCheckoutQuery__
 *
 * To run a query within a React component, call `useCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckoutQuery(baseOptions: Apollo.QueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, options);
      }
export function useCheckoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, options);
        }
export type CheckoutQueryHookResult = ReturnType<typeof useCheckoutQuery>;
export type CheckoutLazyQueryHookResult = ReturnType<typeof useCheckoutLazyQuery>;
export type CheckoutQueryResult = Apollo.QueryResult<CheckoutQuery, CheckoutQueryVariables>;
export const CheckoutPaymentMethodsDocument = gql`
    query CheckoutPaymentMethods($producerId: ID!) {
  checkoutPaymentMethods(producerId: $producerId) {
    id
    name
    brand
    type
  }
}
    `;

/**
 * __useCheckoutPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useCheckoutPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutPaymentMethodsQuery({
 *   variables: {
 *      producerId: // value for 'producerId'
 *   },
 * });
 */
export function useCheckoutPaymentMethodsQuery(baseOptions: Apollo.QueryHookOptions<CheckoutPaymentMethodsQuery, CheckoutPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckoutPaymentMethodsQuery, CheckoutPaymentMethodsQueryVariables>(CheckoutPaymentMethodsDocument, options);
      }
export function useCheckoutPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutPaymentMethodsQuery, CheckoutPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckoutPaymentMethodsQuery, CheckoutPaymentMethodsQueryVariables>(CheckoutPaymentMethodsDocument, options);
        }
export type CheckoutPaymentMethodsQueryHookResult = ReturnType<typeof useCheckoutPaymentMethodsQuery>;
export type CheckoutPaymentMethodsLazyQueryHookResult = ReturnType<typeof useCheckoutPaymentMethodsLazyQuery>;
export type CheckoutPaymentMethodsQueryResult = Apollo.QueryResult<CheckoutPaymentMethodsQuery, CheckoutPaymentMethodsQueryVariables>;
export const CheckoutUserDocument = gql`
    query CheckoutUser {
  user {
    id
    phone
    paymentMethods {
      ...PaymentMethodFragment
    }
    shippingAddresses {
      id
      address1
      city
      isDefault
      province
      zip
    }
    billingAddresses {
      id
      address1
      city
      isDefault
      province
      zip
    }
  }
}
    ${PaymentMethodFragmentFragmentDoc}`;

/**
 * __useCheckoutUserQuery__
 *
 * To run a query within a React component, call `useCheckoutUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckoutUserQuery(baseOptions?: Apollo.QueryHookOptions<CheckoutUserQuery, CheckoutUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckoutUserQuery, CheckoutUserQueryVariables>(CheckoutUserDocument, options);
      }
export function useCheckoutUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutUserQuery, CheckoutUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckoutUserQuery, CheckoutUserQueryVariables>(CheckoutUserDocument, options);
        }
export type CheckoutUserQueryHookResult = ReturnType<typeof useCheckoutUserQuery>;
export type CheckoutUserLazyQueryHookResult = ReturnType<typeof useCheckoutUserLazyQuery>;
export type CheckoutUserQueryResult = Apollo.QueryResult<CheckoutUserQuery, CheckoutUserQueryVariables>;
export const CheckoutCreateDocument = gql`
    mutation CheckoutCreate($input: CreateCheckoutInput!) {
  checkoutCreate(input: $input) {
    ...CheckoutFragment
  }
}
    ${CheckoutFragmentFragmentDoc}`;
export type CheckoutCreateMutationFn = Apollo.MutationFunction<CheckoutCreateMutation, CheckoutCreateMutationVariables>;

/**
 * __useCheckoutCreateMutation__
 *
 * To run a mutation, you first call `useCheckoutCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutCreateMutation, { data, loading, error }] = useCheckoutCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutCreateMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutCreateMutation, CheckoutCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckoutCreateMutation, CheckoutCreateMutationVariables>(CheckoutCreateDocument, options);
      }
export type CheckoutCreateMutationHookResult = ReturnType<typeof useCheckoutCreateMutation>;
export type CheckoutCreateMutationResult = Apollo.MutationResult<CheckoutCreateMutation>;
export type CheckoutCreateMutationOptions = Apollo.BaseMutationOptions<CheckoutCreateMutation, CheckoutCreateMutationVariables>;
export const UpdateCheckoutShippingOptionDocument = gql`
    mutation UpdateCheckoutShippingOption($input: UpdateCheckoutShippingOptionInput!) {
  updateCheckoutShippingOption(input: $input) {
    ...CheckoutFragment
  }
}
    ${CheckoutFragmentFragmentDoc}`;
export type UpdateCheckoutShippingOptionMutationFn = Apollo.MutationFunction<UpdateCheckoutShippingOptionMutation, UpdateCheckoutShippingOptionMutationVariables>;

/**
 * __useUpdateCheckoutShippingOptionMutation__
 *
 * To run a mutation, you first call `useUpdateCheckoutShippingOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckoutShippingOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheckoutShippingOptionMutation, { data, loading, error }] = useUpdateCheckoutShippingOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCheckoutShippingOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCheckoutShippingOptionMutation, UpdateCheckoutShippingOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCheckoutShippingOptionMutation, UpdateCheckoutShippingOptionMutationVariables>(UpdateCheckoutShippingOptionDocument, options);
      }
export type UpdateCheckoutShippingOptionMutationHookResult = ReturnType<typeof useUpdateCheckoutShippingOptionMutation>;
export type UpdateCheckoutShippingOptionMutationResult = Apollo.MutationResult<UpdateCheckoutShippingOptionMutation>;
export type UpdateCheckoutShippingOptionMutationOptions = Apollo.BaseMutationOptions<UpdateCheckoutShippingOptionMutation, UpdateCheckoutShippingOptionMutationVariables>;
export const UpdateCheckoutPaymentMethodDocument = gql`
    mutation UpdateCheckoutPaymentMethod($input: UpdateCheckoutPaymentMethodInput!) {
  updateCheckoutPaymentMethod(input: $input) {
    ...CheckoutFragment
  }
}
    ${CheckoutFragmentFragmentDoc}`;
export type UpdateCheckoutPaymentMethodMutationFn = Apollo.MutationFunction<UpdateCheckoutPaymentMethodMutation, UpdateCheckoutPaymentMethodMutationVariables>;

/**
 * __useUpdateCheckoutPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateCheckoutPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckoutPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheckoutPaymentMethodMutation, { data, loading, error }] = useUpdateCheckoutPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCheckoutPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCheckoutPaymentMethodMutation, UpdateCheckoutPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCheckoutPaymentMethodMutation, UpdateCheckoutPaymentMethodMutationVariables>(UpdateCheckoutPaymentMethodDocument, options);
      }
export type UpdateCheckoutPaymentMethodMutationHookResult = ReturnType<typeof useUpdateCheckoutPaymentMethodMutation>;
export type UpdateCheckoutPaymentMethodMutationResult = Apollo.MutationResult<UpdateCheckoutPaymentMethodMutation>;
export type UpdateCheckoutPaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdateCheckoutPaymentMethodMutation, UpdateCheckoutPaymentMethodMutationVariables>;
export const CompleteCheckoutDocument = gql`
    mutation CompleteCheckout($input: CompleteCheckoutInput!) {
  completeCheckout(input: $input) {
    ...CheckoutFragment
  }
}
    ${CheckoutFragmentFragmentDoc}`;
export type CompleteCheckoutMutationFn = Apollo.MutationFunction<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>;

/**
 * __useCompleteCheckoutMutation__
 *
 * To run a mutation, you first call `useCompleteCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeCheckoutMutation, { data, loading, error }] = useCompleteCheckoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteCheckoutMutation(baseOptions?: Apollo.MutationHookOptions<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>(CompleteCheckoutDocument, options);
      }
export type CompleteCheckoutMutationHookResult = ReturnType<typeof useCompleteCheckoutMutation>;
export type CompleteCheckoutMutationResult = Apollo.MutationResult<CompleteCheckoutMutation>;
export type CompleteCheckoutMutationOptions = Apollo.BaseMutationOptions<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>;
export const CreateCheckoutPaymentMethodDocument = gql`
    mutation CreateCheckoutPaymentMethod($input: CreateCheckoutPaymentMethodInput!) {
  createCheckoutPaymentMethod(input: $input) {
    ...CheckoutFragment
  }
}
    ${CheckoutFragmentFragmentDoc}`;
export type CreateCheckoutPaymentMethodMutationFn = Apollo.MutationFunction<CreateCheckoutPaymentMethodMutation, CreateCheckoutPaymentMethodMutationVariables>;

/**
 * __useCreateCheckoutPaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutPaymentMethodMutation, { data, loading, error }] = useCreateCheckoutPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCheckoutPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreateCheckoutPaymentMethodMutation, CreateCheckoutPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCheckoutPaymentMethodMutation, CreateCheckoutPaymentMethodMutationVariables>(CreateCheckoutPaymentMethodDocument, options);
      }
export type CreateCheckoutPaymentMethodMutationHookResult = ReturnType<typeof useCreateCheckoutPaymentMethodMutation>;
export type CreateCheckoutPaymentMethodMutationResult = Apollo.MutationResult<CreateCheckoutPaymentMethodMutation>;
export type CreateCheckoutPaymentMethodMutationOptions = Apollo.BaseMutationOptions<CreateCheckoutPaymentMethodMutation, CreateCheckoutPaymentMethodMutationVariables>;
export const UpdateCheckoutShippingAddressDocument = gql`
    mutation UpdateCheckoutShippingAddress($input: UpdateCheckoutShippingAddressInput!) {
  updateCheckoutShippingAddress(input: $input) {
    ...CheckoutFragment
  }
}
    ${CheckoutFragmentFragmentDoc}`;
export type UpdateCheckoutShippingAddressMutationFn = Apollo.MutationFunction<UpdateCheckoutShippingAddressMutation, UpdateCheckoutShippingAddressMutationVariables>;

/**
 * __useUpdateCheckoutShippingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCheckoutShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckoutShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheckoutShippingAddressMutation, { data, loading, error }] = useUpdateCheckoutShippingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCheckoutShippingAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCheckoutShippingAddressMutation, UpdateCheckoutShippingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCheckoutShippingAddressMutation, UpdateCheckoutShippingAddressMutationVariables>(UpdateCheckoutShippingAddressDocument, options);
      }
export type UpdateCheckoutShippingAddressMutationHookResult = ReturnType<typeof useUpdateCheckoutShippingAddressMutation>;
export type UpdateCheckoutShippingAddressMutationResult = Apollo.MutationResult<UpdateCheckoutShippingAddressMutation>;
export type UpdateCheckoutShippingAddressMutationOptions = Apollo.BaseMutationOptions<UpdateCheckoutShippingAddressMutation, UpdateCheckoutShippingAddressMutationVariables>;
export const UpdateCheckoutBillingAddressDocument = gql`
    mutation UpdateCheckoutBillingAddress($input: UpdateCheckoutBillingAddressInput!) {
  updateCheckoutBillingAddress(input: $input) {
    ...CheckoutFragment
  }
}
    ${CheckoutFragmentFragmentDoc}`;
export type UpdateCheckoutBillingAddressMutationFn = Apollo.MutationFunction<UpdateCheckoutBillingAddressMutation, UpdateCheckoutBillingAddressMutationVariables>;

/**
 * __useUpdateCheckoutBillingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCheckoutBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckoutBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheckoutBillingAddressMutation, { data, loading, error }] = useUpdateCheckoutBillingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCheckoutBillingAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCheckoutBillingAddressMutation, UpdateCheckoutBillingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCheckoutBillingAddressMutation, UpdateCheckoutBillingAddressMutationVariables>(UpdateCheckoutBillingAddressDocument, options);
      }
export type UpdateCheckoutBillingAddressMutationHookResult = ReturnType<typeof useUpdateCheckoutBillingAddressMutation>;
export type UpdateCheckoutBillingAddressMutationResult = Apollo.MutationResult<UpdateCheckoutBillingAddressMutation>;
export type UpdateCheckoutBillingAddressMutationOptions = Apollo.BaseMutationOptions<UpdateCheckoutBillingAddressMutation, UpdateCheckoutBillingAddressMutationVariables>;
export const AddCheckoutDiscountDocument = gql`
    mutation AddCheckoutDiscount($input: AddCheckoutDiscountInput!) {
  addCheckoutDiscount(input: $input) {
    ...CheckoutFragment
  }
}
    ${CheckoutFragmentFragmentDoc}`;
export type AddCheckoutDiscountMutationFn = Apollo.MutationFunction<AddCheckoutDiscountMutation, AddCheckoutDiscountMutationVariables>;

/**
 * __useAddCheckoutDiscountMutation__
 *
 * To run a mutation, you first call `useAddCheckoutDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCheckoutDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCheckoutDiscountMutation, { data, loading, error }] = useAddCheckoutDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCheckoutDiscountMutation(baseOptions?: Apollo.MutationHookOptions<AddCheckoutDiscountMutation, AddCheckoutDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCheckoutDiscountMutation, AddCheckoutDiscountMutationVariables>(AddCheckoutDiscountDocument, options);
      }
export type AddCheckoutDiscountMutationHookResult = ReturnType<typeof useAddCheckoutDiscountMutation>;
export type AddCheckoutDiscountMutationResult = Apollo.MutationResult<AddCheckoutDiscountMutation>;
export type AddCheckoutDiscountMutationOptions = Apollo.BaseMutationOptions<AddCheckoutDiscountMutation, AddCheckoutDiscountMutationVariables>;
export const VerifyInviteCodeDocument = gql`
    query VerifyInviteCode($inviteCode: String!) {
  verifyInviteCode(inviteCode: $inviteCode) {
    ...CustomerFragment
  }
}
    ${CustomerFragmentFragmentDoc}`;

/**
 * __useVerifyInviteCodeQuery__
 *
 * To run a query within a React component, call `useVerifyInviteCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyInviteCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyInviteCodeQuery({
 *   variables: {
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useVerifyInviteCodeQuery(baseOptions: Apollo.QueryHookOptions<VerifyInviteCodeQuery, VerifyInviteCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyInviteCodeQuery, VerifyInviteCodeQueryVariables>(VerifyInviteCodeDocument, options);
      }
export function useVerifyInviteCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyInviteCodeQuery, VerifyInviteCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyInviteCodeQuery, VerifyInviteCodeQueryVariables>(VerifyInviteCodeDocument, options);
        }
export type VerifyInviteCodeQueryHookResult = ReturnType<typeof useVerifyInviteCodeQuery>;
export type VerifyInviteCodeLazyQueryHookResult = ReturnType<typeof useVerifyInviteCodeLazyQuery>;
export type VerifyInviteCodeQueryResult = Apollo.QueryResult<VerifyInviteCodeQuery, VerifyInviteCodeQueryVariables>;
export const CustomerAccountCreateDocument = gql`
    mutation CustomerAccountCreate($input: CustomerAccountCreateInput!) {
  customerAccountCreate(input: $input) {
    ...CustomerFragment
  }
}
    ${CustomerFragmentFragmentDoc}`;
export type CustomerAccountCreateMutationFn = Apollo.MutationFunction<CustomerAccountCreateMutation, CustomerAccountCreateMutationVariables>;

/**
 * __useCustomerAccountCreateMutation__
 *
 * To run a mutation, you first call `useCustomerAccountCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAccountCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAccountCreateMutation, { data, loading, error }] = useCustomerAccountCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerAccountCreateMutation(baseOptions?: Apollo.MutationHookOptions<CustomerAccountCreateMutation, CustomerAccountCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerAccountCreateMutation, CustomerAccountCreateMutationVariables>(CustomerAccountCreateDocument, options);
      }
export type CustomerAccountCreateMutationHookResult = ReturnType<typeof useCustomerAccountCreateMutation>;
export type CustomerAccountCreateMutationResult = Apollo.MutationResult<CustomerAccountCreateMutation>;
export type CustomerAccountCreateMutationOptions = Apollo.BaseMutationOptions<CustomerAccountCreateMutation, CustomerAccountCreateMutationVariables>;
export const OrdersDocument = gql`
    query Orders($take: Int, $skip: Int) {
  orders(take: $take, skip: $skip) {
    edges {
      node {
        id
        name
        status
        financialStatus
        grandTotal
        producer {
          name
          logo {
            src
            base64
          }
        }
        shipping {
          type
          date
        }
      }
    }
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OrderDocument = gql`
    query Order($id: ID!) {
  order(id: $id) {
    ...OrderFragment
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const PayOrderDocument = gql`
    mutation PayOrder($input: PayOrderInput!) {
  payOrder(input: $input) {
    ...OrderFragment
  }
}
    ${OrderFragmentFragmentDoc}`;
export type PayOrderMutationFn = Apollo.MutationFunction<PayOrderMutation, PayOrderMutationVariables>;

/**
 * __usePayOrderMutation__
 *
 * To run a mutation, you first call `usePayOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payOrderMutation, { data, loading, error }] = usePayOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayOrderMutation(baseOptions?: Apollo.MutationHookOptions<PayOrderMutation, PayOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayOrderMutation, PayOrderMutationVariables>(PayOrderDocument, options);
      }
export type PayOrderMutationHookResult = ReturnType<typeof usePayOrderMutation>;
export type PayOrderMutationResult = Apollo.MutationResult<PayOrderMutation>;
export type PayOrderMutationOptions = Apollo.BaseMutationOptions<PayOrderMutation, PayOrderMutationVariables>;
export const OrderItemsDocument = gql`
    query OrderItems($id: ID!) {
  order(id: $id) {
    id
    name
    producer {
      slug
    }
  }
  orderItems(id: $id) {
    id
    name
    variant {
      name
      unit
      type
    }
    available
    category
    subCategory
    image {
      src
      base64
      blurhash
      alt
    }
    quantity
    unitQuantity
    unitPrice
  }
}
    `;

/**
 * __useOrderItemsQuery__
 *
 * To run a query within a React component, call `useOrderItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderItemsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderItemsQuery(baseOptions: Apollo.QueryHookOptions<OrderItemsQuery, OrderItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderItemsQuery, OrderItemsQueryVariables>(OrderItemsDocument, options);
      }
export function useOrderItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderItemsQuery, OrderItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderItemsQuery, OrderItemsQueryVariables>(OrderItemsDocument, options);
        }
export type OrderItemsQueryHookResult = ReturnType<typeof useOrderItemsQuery>;
export type OrderItemsLazyQueryHookResult = ReturnType<typeof useOrderItemsLazyQuery>;
export type OrderItemsQueryResult = Apollo.QueryResult<OrderItemsQuery, OrderItemsQueryVariables>;
export const CreatePlaidPaymentMethodDocument = gql`
    mutation CreatePlaidPaymentMethod($input: CreatePlaidPaymentMethodInput!) {
  createPlaidPaymentMethod(input: $input) {
    ...PaymentMethodFragment
  }
}
    ${PaymentMethodFragmentFragmentDoc}`;
export type CreatePlaidPaymentMethodMutationFn = Apollo.MutationFunction<CreatePlaidPaymentMethodMutation, CreatePlaidPaymentMethodMutationVariables>;

/**
 * __useCreatePlaidPaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePlaidPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaidPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaidPaymentMethodMutation, { data, loading, error }] = useCreatePlaidPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlaidPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaidPaymentMethodMutation, CreatePlaidPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaidPaymentMethodMutation, CreatePlaidPaymentMethodMutationVariables>(CreatePlaidPaymentMethodDocument, options);
      }
export type CreatePlaidPaymentMethodMutationHookResult = ReturnType<typeof useCreatePlaidPaymentMethodMutation>;
export type CreatePlaidPaymentMethodMutationResult = Apollo.MutationResult<CreatePlaidPaymentMethodMutation>;
export type CreatePlaidPaymentMethodMutationOptions = Apollo.BaseMutationOptions<CreatePlaidPaymentMethodMutation, CreatePlaidPaymentMethodMutationVariables>;
export const CreateCardPaymentMethodDocument = gql`
    mutation CreateCardPaymentMethod($input: CreateCardPaymentMethodInput!) {
  createCardPaymentMethod(input: $input) {
    ...PaymentMethodFragment
  }
}
    ${PaymentMethodFragmentFragmentDoc}`;
export type CreateCardPaymentMethodMutationFn = Apollo.MutationFunction<CreateCardPaymentMethodMutation, CreateCardPaymentMethodMutationVariables>;

/**
 * __useCreateCardPaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreateCardPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardPaymentMethodMutation, { data, loading, error }] = useCreateCardPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCardPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreateCardPaymentMethodMutation, CreateCardPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCardPaymentMethodMutation, CreateCardPaymentMethodMutationVariables>(CreateCardPaymentMethodDocument, options);
      }
export type CreateCardPaymentMethodMutationHookResult = ReturnType<typeof useCreateCardPaymentMethodMutation>;
export type CreateCardPaymentMethodMutationResult = Apollo.MutationResult<CreateCardPaymentMethodMutation>;
export type CreateCardPaymentMethodMutationOptions = Apollo.BaseMutationOptions<CreateCardPaymentMethodMutation, CreateCardPaymentMethodMutationVariables>;
export const UpdateDefaultCardPaymentDocument = gql`
    mutation UpdateDefaultCardPayment($id: ID!) {
  updateDefaultCardPayment(id: $id) {
    id
  }
}
    `;
export type UpdateDefaultCardPaymentMutationFn = Apollo.MutationFunction<UpdateDefaultCardPaymentMutation, UpdateDefaultCardPaymentMutationVariables>;

/**
 * __useUpdateDefaultCardPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultCardPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultCardPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultCardPaymentMutation, { data, loading, error }] = useUpdateDefaultCardPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDefaultCardPaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultCardPaymentMutation, UpdateDefaultCardPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDefaultCardPaymentMutation, UpdateDefaultCardPaymentMutationVariables>(UpdateDefaultCardPaymentDocument, options);
      }
export type UpdateDefaultCardPaymentMutationHookResult = ReturnType<typeof useUpdateDefaultCardPaymentMutation>;
export type UpdateDefaultCardPaymentMutationResult = Apollo.MutationResult<UpdateDefaultCardPaymentMutation>;
export type UpdateDefaultCardPaymentMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultCardPaymentMutation, UpdateDefaultCardPaymentMutationVariables>;
export const DeleteCardPaymentDocument = gql`
    mutation DeleteCardPayment($id: ID!) {
  deleteCardPaymentMethod(id: $id) {
    id
  }
}
    `;
export type DeleteCardPaymentMutationFn = Apollo.MutationFunction<DeleteCardPaymentMutation, DeleteCardPaymentMutationVariables>;

/**
 * __useDeleteCardPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteCardPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardPaymentMutation, { data, loading, error }] = useDeleteCardPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCardPaymentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardPaymentMutation, DeleteCardPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCardPaymentMutation, DeleteCardPaymentMutationVariables>(DeleteCardPaymentDocument, options);
      }
export type DeleteCardPaymentMutationHookResult = ReturnType<typeof useDeleteCardPaymentMutation>;
export type DeleteCardPaymentMutationResult = Apollo.MutationResult<DeleteCardPaymentMutation>;
export type DeleteCardPaymentMutationOptions = Apollo.BaseMutationOptions<DeleteCardPaymentMutation, DeleteCardPaymentMutationVariables>;
export const ProducerIdsDocument = gql`
    query ProducerIds($take: Int) {
  producers(take: $take) {
    edges {
      node {
        slug
      }
    }
  }
}
    `;

/**
 * __useProducerIdsQuery__
 *
 * To run a query within a React component, call `useProducerIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProducerIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProducerIdsQuery({
 *   variables: {
 *      take: // value for 'take'
 *   },
 * });
 */
export function useProducerIdsQuery(baseOptions?: Apollo.QueryHookOptions<ProducerIdsQuery, ProducerIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProducerIdsQuery, ProducerIdsQueryVariables>(ProducerIdsDocument, options);
      }
export function useProducerIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProducerIdsQuery, ProducerIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProducerIdsQuery, ProducerIdsQueryVariables>(ProducerIdsDocument, options);
        }
export type ProducerIdsQueryHookResult = ReturnType<typeof useProducerIdsQuery>;
export type ProducerIdsLazyQueryHookResult = ReturnType<typeof useProducerIdsLazyQuery>;
export type ProducerIdsQueryResult = Apollo.QueryResult<ProducerIdsQuery, ProducerIdsQueryVariables>;
export const ProducerDocument = gql`
    query Producer($slug: String!, $take: Int, $level: PriceLevel) {
  producer(slug: $slug) {
    id
    name
    slug
    description {
      retail
      wholesale
    }
    city
    province
    image {
      src
      base64
    }
    logo {
      src
      base64
    }
    products(take: $take, level: $level) {
      edges {
        node {
          ...ProductNode
        }
      }
    }
  }
}
    ${ProductNodeFragmentDoc}`;

/**
 * __useProducerQuery__
 *
 * To run a query within a React component, call `useProducerQuery` and pass it any options that fit your needs.
 * When your component renders, `useProducerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProducerQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      take: // value for 'take'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useProducerQuery(baseOptions: Apollo.QueryHookOptions<ProducerQuery, ProducerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProducerQuery, ProducerQueryVariables>(ProducerDocument, options);
      }
export function useProducerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProducerQuery, ProducerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProducerQuery, ProducerQueryVariables>(ProducerDocument, options);
        }
export type ProducerQueryHookResult = ReturnType<typeof useProducerQuery>;
export type ProducerLazyQueryHookResult = ReturnType<typeof useProducerLazyQuery>;
export type ProducerQueryResult = Apollo.QueryResult<ProducerQuery, ProducerQueryVariables>;
export const PreRenderProductsDocument = gql`
    query PreRenderProducts($take: Int, $level: PriceLevel) {
  products(take: $take, level: $level) {
    edges {
      node {
        id
        producer {
          slug
        }
      }
    }
  }
}
    `;

/**
 * __usePreRenderProductsQuery__
 *
 * To run a query within a React component, call `usePreRenderProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreRenderProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreRenderProductsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      level: // value for 'level'
 *   },
 * });
 */
export function usePreRenderProductsQuery(baseOptions?: Apollo.QueryHookOptions<PreRenderProductsQuery, PreRenderProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreRenderProductsQuery, PreRenderProductsQueryVariables>(PreRenderProductsDocument, options);
      }
export function usePreRenderProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreRenderProductsQuery, PreRenderProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreRenderProductsQuery, PreRenderProductsQueryVariables>(PreRenderProductsDocument, options);
        }
export type PreRenderProductsQueryHookResult = ReturnType<typeof usePreRenderProductsQuery>;
export type PreRenderProductsLazyQueryHookResult = ReturnType<typeof usePreRenderProductsLazyQuery>;
export type PreRenderProductsQueryResult = Apollo.QueryResult<PreRenderProductsQuery, PreRenderProductsQueryVariables>;
export const ProductDocument = gql`
    query Product($id: ID!, $level: PriceLevel) {
  product(id: $id, level: $level) {
    ...ProductNode
    description
    producer {
      name
      city
      description {
        retail
        wholesale
      }
      province
      slug
      image {
        src
        base64
      }
      logo {
        src
        base64
      }
    }
  }
}
    ${ProductNodeFragmentDoc}`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ShippingDocument = gql`
    query Shipping($producerId: ID!) {
  shipping(producerId: $producerId) {
    type
    edges {
      node {
        next
        until
        location {
          id
          name
          address1
          city
          province
          zip
          pickupStart
          pickupEnd
          image {
            src
          }
        }
      }
    }
  }
}
    `;

/**
 * __useShippingQuery__
 *
 * To run a query within a React component, call `useShippingQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippingQuery({
 *   variables: {
 *      producerId: // value for 'producerId'
 *   },
 * });
 */
export function useShippingQuery(baseOptions: Apollo.QueryHookOptions<ShippingQuery, ShippingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShippingQuery, ShippingQueryVariables>(ShippingDocument, options);
      }
export function useShippingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShippingQuery, ShippingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShippingQuery, ShippingQueryVariables>(ShippingDocument, options);
        }
export type ShippingQueryHookResult = ReturnType<typeof useShippingQuery>;
export type ShippingLazyQueryHookResult = ReturnType<typeof useShippingLazyQuery>;
export type ShippingQueryResult = Apollo.QueryResult<ShippingQuery, ShippingQueryVariables>;
export const AccountUserDocument = gql`
    query AccountUser {
  user {
    ...UserFragment
    shippingAddresses {
      ...UserAddressFragment
    }
    billingAddresses {
      ...UserAddressFragment
    }
  }
}
    ${UserFragmentFragmentDoc}
${UserAddressFragmentFragmentDoc}`;

/**
 * __useAccountUserQuery__
 *
 * To run a query within a React component, call `useAccountUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountUserQuery(baseOptions?: Apollo.QueryHookOptions<AccountUserQuery, AccountUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountUserQuery, AccountUserQueryVariables>(AccountUserDocument, options);
      }
export function useAccountUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountUserQuery, AccountUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountUserQuery, AccountUserQueryVariables>(AccountUserDocument, options);
        }
export type AccountUserQueryHookResult = ReturnType<typeof useAccountUserQuery>;
export type AccountUserLazyQueryHookResult = ReturnType<typeof useAccountUserLazyQuery>;
export type AccountUserQueryResult = Apollo.QueryResult<AccountUserQuery, AccountUserQueryVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  user {
    id
    firstName
    lastName
    email
    team {
      name
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const UserTeamMembersDocument = gql`
    query UserTeamMembers {
  user {
    ...UserTeamMembersFragment
  }
}
    ${UserTeamMembersFragmentFragmentDoc}`;

/**
 * __useUserTeamMembersQuery__
 *
 * To run a query within a React component, call `useUserTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTeamMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTeamMembersQuery(baseOptions?: Apollo.QueryHookOptions<UserTeamMembersQuery, UserTeamMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTeamMembersQuery, UserTeamMembersQueryVariables>(UserTeamMembersDocument, options);
      }
export function useUserTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTeamMembersQuery, UserTeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTeamMembersQuery, UserTeamMembersQueryVariables>(UserTeamMembersDocument, options);
        }
export type UserTeamMembersQueryHookResult = ReturnType<typeof useUserTeamMembersQuery>;
export type UserTeamMembersLazyQueryHookResult = ReturnType<typeof useUserTeamMembersLazyQuery>;
export type UserTeamMembersQueryResult = Apollo.QueryResult<UserTeamMembersQuery, UserTeamMembersQueryVariables>;
export const UserPaymentMethodsDocument = gql`
    query UserPaymentMethods {
  user {
    id
    paymentMethods {
      ...PaymentMethodFragment
    }
  }
}
    ${PaymentMethodFragmentFragmentDoc}`;

/**
 * __useUserPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useUserPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<UserPaymentMethodsQuery, UserPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPaymentMethodsQuery, UserPaymentMethodsQueryVariables>(UserPaymentMethodsDocument, options);
      }
export function useUserPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPaymentMethodsQuery, UserPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPaymentMethodsQuery, UserPaymentMethodsQueryVariables>(UserPaymentMethodsDocument, options);
        }
export type UserPaymentMethodsQueryHookResult = ReturnType<typeof useUserPaymentMethodsQuery>;
export type UserPaymentMethodsLazyQueryHookResult = ReturnType<typeof useUserPaymentMethodsLazyQuery>;
export type UserPaymentMethodsQueryResult = Apollo.QueryResult<UserPaymentMethodsQuery, UserPaymentMethodsQueryVariables>;
export const UserAddressesDocument = gql`
    query UserAddresses {
  user {
    id
    shippingAddresses {
      ...UserAddressFragment
    }
    billingAddresses {
      ...UserAddressFragment
    }
  }
}
    ${UserAddressFragmentFragmentDoc}`;

/**
 * __useUserAddressesQuery__
 *
 * To run a query within a React component, call `useUserAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAddressesQuery(baseOptions?: Apollo.QueryHookOptions<UserAddressesQuery, UserAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAddressesQuery, UserAddressesQueryVariables>(UserAddressesDocument, options);
      }
export function useUserAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAddressesQuery, UserAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAddressesQuery, UserAddressesQueryVariables>(UserAddressesDocument, options);
        }
export type UserAddressesQueryHookResult = ReturnType<typeof useUserAddressesQuery>;
export type UserAddressesLazyQueryHookResult = ReturnType<typeof useUserAddressesLazyQuery>;
export type UserAddressesQueryResult = Apollo.QueryResult<UserAddressesQuery, UserAddressesQueryVariables>;
export const CreateUserAddressDocument = gql`
    mutation CreateUserAddress($input: CreateUserAddressInput!) {
  createUserAddress(input: $input) {
    id
    shippingAddresses {
      ...UserAddressFragment
    }
    billingAddresses {
      ...UserAddressFragment
    }
  }
}
    ${UserAddressFragmentFragmentDoc}`;
export type CreateUserAddressMutationFn = Apollo.MutationFunction<CreateUserAddressMutation, CreateUserAddressMutationVariables>;

/**
 * __useCreateUserAddressMutation__
 *
 * To run a mutation, you first call `useCreateUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAddressMutation, { data, loading, error }] = useCreateUserAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserAddressMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserAddressMutation, CreateUserAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserAddressMutation, CreateUserAddressMutationVariables>(CreateUserAddressDocument, options);
      }
export type CreateUserAddressMutationHookResult = ReturnType<typeof useCreateUserAddressMutation>;
export type CreateUserAddressMutationResult = Apollo.MutationResult<CreateUserAddressMutation>;
export type CreateUserAddressMutationOptions = Apollo.BaseMutationOptions<CreateUserAddressMutation, CreateUserAddressMutationVariables>;
export const DeleteUserAddressDocument = gql`
    mutation DeleteUserAddress($id: ID!) {
  deleteUserAddress(id: $id) {
    id
    shippingAddresses {
      ...UserAddressFragment
    }
    billingAddresses {
      ...UserAddressFragment
    }
  }
}
    ${UserAddressFragmentFragmentDoc}`;
export type DeleteUserAddressMutationFn = Apollo.MutationFunction<DeleteUserAddressMutation, DeleteUserAddressMutationVariables>;

/**
 * __useDeleteUserAddressMutation__
 *
 * To run a mutation, you first call `useDeleteUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAddressMutation, { data, loading, error }] = useDeleteUserAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserAddressMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserAddressMutation, DeleteUserAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserAddressMutation, DeleteUserAddressMutationVariables>(DeleteUserAddressDocument, options);
      }
export type DeleteUserAddressMutationHookResult = ReturnType<typeof useDeleteUserAddressMutation>;
export type DeleteUserAddressMutationResult = Apollo.MutationResult<DeleteUserAddressMutation>;
export type DeleteUserAddressMutationOptions = Apollo.BaseMutationOptions<DeleteUserAddressMutation, DeleteUserAddressMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    email
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserAddressDocument = gql`
    mutation UpdateUserAddress($input: UpdateUserAddressInput!) {
  updateUserAddress(input: $input) {
    id
    shippingAddresses {
      ...UserAddressFragment
    }
    billingAddresses {
      ...UserAddressFragment
    }
  }
}
    ${UserAddressFragmentFragmentDoc}`;
export type UpdateUserAddressMutationFn = Apollo.MutationFunction<UpdateUserAddressMutation, UpdateUserAddressMutationVariables>;

/**
 * __useUpdateUserAddressMutation__
 *
 * To run a mutation, you first call `useUpdateUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAddressMutation, { data, loading, error }] = useUpdateUserAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAddressMutation, UpdateUserAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAddressMutation, UpdateUserAddressMutationVariables>(UpdateUserAddressDocument, options);
      }
export type UpdateUserAddressMutationHookResult = ReturnType<typeof useUpdateUserAddressMutation>;
export type UpdateUserAddressMutationResult = Apollo.MutationResult<UpdateUserAddressMutation>;
export type UpdateUserAddressMutationOptions = Apollo.BaseMutationOptions<UpdateUserAddressMutation, UpdateUserAddressMutationVariables>;
export const CreateTeamMemberDocument = gql`
    mutation CreateTeamMember($input: CreateTeamMemberInput!) {
  createTeamMember(input: $input) {
    ...UserTeamMembersFragment
  }
}
    ${UserTeamMembersFragmentFragmentDoc}`;
export type CreateTeamMemberMutationFn = Apollo.MutationFunction<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>;

/**
 * __useCreateTeamMemberMutation__
 *
 * To run a mutation, you first call `useCreateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMemberMutation, { data, loading, error }] = useCreateTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>(CreateTeamMemberDocument, options);
      }
export type CreateTeamMemberMutationHookResult = ReturnType<typeof useCreateTeamMemberMutation>;
export type CreateTeamMemberMutationResult = Apollo.MutationResult<CreateTeamMemberMutation>;
export type CreateTeamMemberMutationOptions = Apollo.BaseMutationOptions<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>;
export const UpdateTeamMemberDocument = gql`
    mutation UpdateTeamMember($input: UpdateTeamMemberInput!) {
  updateTeamMember(input: $input) {
    ...UserTeamMembersFragment
  }
}
    ${UserTeamMembersFragmentFragmentDoc}`;
export type UpdateTeamMemberMutationFn = Apollo.MutationFunction<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>;

/**
 * __useUpdateTeamMemberMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMemberMutation, { data, loading, error }] = useUpdateTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>(UpdateTeamMemberDocument, options);
      }
export type UpdateTeamMemberMutationHookResult = ReturnType<typeof useUpdateTeamMemberMutation>;
export type UpdateTeamMemberMutationResult = Apollo.MutationResult<UpdateTeamMemberMutation>;
export type UpdateTeamMemberMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>;
export const DeleteTeamMemberDocument = gql`
    mutation DeleteTeamMember($id: ID!) {
  deleteTeamMember(id: $id) {
    ...UserTeamMembersFragment
  }
}
    ${UserTeamMembersFragmentFragmentDoc}`;
export type DeleteTeamMemberMutationFn = Apollo.MutationFunction<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>;

/**
 * __useDeleteTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMemberMutation, { data, loading, error }] = useDeleteTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>(DeleteTeamMemberDocument, options);
      }
export type DeleteTeamMemberMutationHookResult = ReturnType<typeof useDeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationResult = Apollo.MutationResult<DeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    accessToken
    refreshToken
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    success
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;